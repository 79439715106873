import React, { Fragment, useEffect, useState } from 'react'
import vesselQueries from '../../queries/vesselQueries';
import { Pencil, SmilePlus } from 'lucide-react';
import moment from 'moment';
import { VesselFormData } from '../../data/VesselForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { generateValidationSchema } from '../../utils/validationSchema';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import chapterQueries from '../../queries/chapterQueries';
import BreadCrumbs from '../../components/BreadCrumbs';
import { RouteConstant } from '../../routes/RouteConstant';
import { setHeaderText, setLoader } from '../../redux/reducers/optionsReducers';
import Loader from '../../components/Loader';
import VesselDetailScreenChaptersAssign from './VesselDetailScreenChaptersAssign';
import { useApi } from '../../components/ApiProvider';
import DrawerComponent from '../../components/DrawerComponent';
import { Badge, Button } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
import { setMaster, setUser, setVessel } from '../../redux/reducers/masterReducers';
import { setPage } from '../../redux/reducers/paginationReducers';

const breadcrumb = [
    {
        label: "Vessel",
        path: RouteConstant.VESSEL,
        roles: ["SADMIN"]
    },
    {
      label: "Vessel Details",
      roles: ["SADMIN"]
    }
]

const VesselDetailsScreen = () => {

    const [result, setResult] = useState({})
    const [chapters, setChapters] = useState([])

    const user = useSelector((state)=> state.auth.user)
    const vessel = useSelector((state)=> state.master.vessel)

    const {apiData} = useApi()

    const navigate = useNavigate()

    const dispatch = useDispatch()
    const master = useSelector((state)=> state.master)
    const loader = useSelector((state)=> state.options.loader)

    const {register, handleSubmit, getValues, setValue, clearErrors, formState:{errors}} = useForm({
        resolver: yupResolver(generateValidationSchema(VesselFormData))
    })

    const vesselView = vesselQueries.useVesselMutationView(
        (res)=>{
            dispatch(setLoader(false))
            setResult(res?.data)
            dispatch(setHeaderText(`Vessel Details -  ${res?.data?.name}`))
        }
    )
    
    useEffect(() => {
        dispatch(setLoader(true))
        if(user?.role?.role === "SADMIN"){
            vesselView.mutateAsync(vessel.id)
        }else{
            vesselView.mutateAsync(user?.vessel?.id)
        }
    }, []);
    
    const updateMaster = vesselQueries.useVesselMutationUpdate(
        (res)=>{
            if(res?.message === "Error"){
                {res?.error?.map((error, index) => (
                    Object?.values(error).map((errorMessage, innerIndex) => (
                        toast.error(errorMessage)
                    ))
                ))}
                return
            }
            if(res?.message=== "Success"){
                toast.success("Successfully updated vessel")
                vesselView.mutateAsync(master.id)
            }
        }
    )

    const chapterAllList = chapterQueries.useChapterMutationAllList(
        (res)=>{
            setChapters(res?.data)
        }
    )

    useEffect(() => {
        if(user?.role?.role === "SADMIN"){
            chapterAllList.mutateAsync(vessel.id)
        }
    }, [apiData]);


    const gotoAssign = (item)=>{
        dispatch(setLoader(true))
        dispatch(setPage(0))
        dispatch(setHeaderText("Assign Question"))
        dispatch(setUser(item))
        navigate(RouteConstant.ASSIGNLIST)
    }

    const handleInspection =()=>{
        dispatch(setMaster({
            id: result.id,
            header: result.name
          }
        ))
        dispatch(setVessel({
          id: result?.id,
          name: result?.name,
          imo: result?.imo,
          built: result?.built,
          type: result?.type,
          dwt: result?.dwt
        }))
        dispatch(setHeaderText(`Vessel : ${result?.name}`))
        navigate(RouteConstant.INSPECTIONHISTORY)
      }

    
    if(loader){
        return <Loader />
    }

  return (
    <Fragment>
        
        <div className='mb-4'>
            <BreadCrumbs data={breadcrumb} />
        </div>

        <div className="w-full max-[410px]:p-3  text-[14px]">
            <div>
                <div className="ship-card border-vesselOrange card-shadow bg-white flex flex-col gap-4">
                    <div className="flex justify-between p-2 font-[600]">
                        <div>
                            <div className="text-[12px] text-[#737373]">Vessel Name</div>
                            <div className="text-[20px]">{result?.name}</div>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 p-2 font-[600] text-[12px]">
                        <div className="col-span-3 flex flex-col gap-3">
                            <div className="text-[#737373]">Updated by</div>
                            <div>{result?.updatedBy?.fname} {result?.updatedBy?.lname}</div>
                        </div>
                        <div className="col-span-3 flex flex-col gap-3">
                            <div className="text-[#737373]">Updated On</div>
                            <div>{moment(result?.updatedAt).format("DD-MM-YYYY hh:mm a")}</div>
                        </div>
                        <div className="col-span-3 flex flex-col gap-3">
                            <div className="text-[#737373]">IMO No.</div>
                            <div>{result?.imo_no}</div>
                        </div>
                        <div className="col-span-3 flex flex-col gap-3">
                            <div className="text-[#737373]">Built</div>
                            <div>{result?.built}</div>
                        </div>
                    </div>
                    <div className="grid gap-6 grid-cols-12 p-2 font-[600] text-[12px]">
                        <div className="col-span-3 flex flex-col gap-3">
                            <div className="text-[#737373]">Type</div>
                            <div>{result?.type}</div>
                        </div>
                        <div className="col-span-3 flex flex-col gap-3">
                            <div className="text-[#737373]">DWT</div>
                            <div>{result?.dwt}</div>
                        </div>
                    </div>
                    {
                        user?.role?.role === "CAPT" &&
                        <div className="grid grid-cols-12 max-[1553px]:grid-cols-9 max-[1095px]:grid-cols-6 max-[693px]:grid-cols-3 gap-6 p-2 text-[12px] font-semibold">
                            {
                                result?.users?.map((user, index) => (
                                    <div key={index} className="col-span-3 flex justify-between items-center gap-6 bg-[#f5f5f5] rounded-[10px] px-4 py-3 card-shadow">                                    
                                        <div className='flex gap-4 items-center'>
                                            <div className='bg-primary text-white min-w-[35px] min-h-[35px] w-[35px] h-[35px] font-bold rounded-full flex justify-center items-center'>
                                                {(user?.fname).slice(0, 1).toUpperCase()}{(user?.lname).slice(0, 1).toUpperCase()}
                                            </div>
                                            <div>
                                                {/* <div>{user?.fname} {user?.lname}</div> */}
                                                <div>{user?.role?.name}</div>
                                                {/* <div className='text-[#737373] font-medium italic'>{user?.role?.name}</div> */}
                                            </div>
                                        </div>
                                        <div>
                                            <Badge classNames={{
                                                badge: 'text-[10px] text-white',
                                            }} color='primary' content={user?.assignedTo?.length}>
                                                <Button onClick={()=>gotoAssign(user)} className='font-medium bg-iconBgColorMessage text-white' size='sm'>
                                                    Questions
                                                </Button>
                                            </Badge>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    }
                    <div className="flex justify-end p-2 gap-2">
                        <Button onClick={handleInspection} className='font-medium bg-primary text-white' size='sm'>
                            View Inspection
                        </Button>
                        <DrawerComponent edit={true} data={result} inputFields={VesselFormData} btnName="Update Vessel" header="Update Vessel" entityForm={updateMaster}  />
                    </div>
                </div>
            </div>
        </div>


        {
            user?.role?.role === "SADMIN" &&
            <div>
                <VesselDetailScreenChaptersAssign chapter={chapters} />
            </div>
        }

    </Fragment>
  )
}

export default VesselDetailsScreen