import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  qid: '',
  currentPage: 0,
};

const questionSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    setQuestionId: (state, action) => {
      state.qid = action.payload;
    },
    removeQuestionId: (state) => {
      state.qid = ""
    },
    setQuestionPage: (state, action) => {
      state.currentPage = action.payload
    },
    resetQuestionPage: (state) => {
      state.currentPage = initialState.currentPage
    }
  },
});

export const { setQuestionId, removeQuestionId, setQuestionPage, resetQuestionPage } = questionSlice.actions;
export default questionSlice.reducer;