import React from 'react'
import EmptyImage from '../assets/images/js/EmptyImage'

const Empty = () => {
  return (
    <div className='text-gray-600 text-[16px] font-semibold flex flex-col gap-3 justify-center items-center h-[400px]'>
        <EmptyImage />
        <div>No entries found</div>
    </div>
  )
}

export default Empty