import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './authReducers';
import questionReducers from './questionReducers';
import optionsReducers from './optionsReducers';
import paginationReducers from './paginationReducers';
import masterReducers from './masterReducers';
import inspectionReducers from './inspectionReducers';
import companyReducers from './companyReducers';

const rootReducer = combineReducers({
  auth: authReducer,
  question: questionReducers,
  options: optionsReducers,
  master: masterReducers,
  pagination: paginationReducers,
  inspection: inspectionReducers,
  company: companyReducers
})

export default rootReducer;