import React, { createContext, useContext, useState } from 'react';

const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
 
  const [apiData, setApiData] = useState(null);

  const setApiDataAndNotify = (data) => {
    setApiData(data);
  };

  return (
    <ApiContext.Provider value={{ apiData, setApiDataAndNotify }}>
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = () => {
  return useContext(ApiContext);
};