import apiRequest from '../apis/api-request';
import { selectAuthToken } from '../redux/reducers/authReducers';
import { store } from '../redux/store';

const commentServices = {

    commentCreate: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "POST",
            url: `/api/comment/`,
            data: data,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },

    questionCommentCreate: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "POST",
            url: `/api/comment/question`,
            data: data,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },

    questionCommentView: async (data) => {
        const question = data?.question ? data?.question : ""
        const inspection = data?.inspection ? data?.inspection : ""
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "GET",
            url: `/api/comment/question?question=${question}&inspection=${inspection}`,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },

};

export default commentServices;