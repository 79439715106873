import { useMutation } from "react-query";
import fileuploadService from "../services/fileuploadService";

const useFileUploadMutationMultiple = (onData) => {
  return useMutation(fileuploadService.multipleFileUpload, {
    onSuccess: (data) => onData(data),
  });
};

const fileuploadQueries = {
  useFileUploadMutationMultiple
};

export default fileuploadQueries;
