import React, { Fragment } from 'react'

export const ValidationError = ({error}) => {
  return (
    <div className='text-red-600 font-semibold'>
        {error?.message}
    </div>
  )
}

export const ValidationErrorMaster = ({error}) => {
  return (
    <div className='text-red-600 text-[12px]'>
        {error?.message}
    </div>
  )
}