import { ChevronsUp, ClipboardList, LayoutDashboard, LogOut, Ship, UserRound } from 'lucide-react'
import { RouteConstant } from '../routes/RouteConstant'
import { useSelector } from 'react-redux'

const SuperAdminMenu = [
    {
      label: "Dashboard",
      url: RouteConstant.DASHBOARD,
      icon: <LayoutDashboard size="20" />
    },
    {
      label: "Users",
      url: RouteConstant.USERS,
      icon: <UserRound size="20" />
    },
    {
      label: "Vessel", 
      url: RouteConstant.VESSEL, 
      icon: <Ship size="20" color='white' />
    },
    {
      label: "Masters",
      expand: true,
      icon: <ChevronsUp size="20" />,
      more: [
        {label: "Chapter", url: RouteConstant.CHAPTER, icon: <ClipboardList size="16" />},
      ]
    },

    {
      label: "OCIMF",
      expand: true,
      icon: <ChevronsUp size="20" />,
      more: [
        {label: "Vessel", url: RouteConstant.OCIMFVESSEL, icon: <Ship size="20" color='white' />},
      ]
    },
    {
      label: "Logout",
      icon: <LogOut size="20" />
    }
]

const CaptainMenu = [
  {
    label: "Vessel", 
    url: RouteConstant.VESSELDETAILS, 
    icon: <Ship size="20" color='white' />
  },
  {
    label: "Logout",
    url: RouteConstant.DASHBOARD,
    icon: <LogOut size="20" />
  }
]

const ChiefEngineerMenu = [
  {
    label: "Vessel", 
    url: RouteConstant.VESSELDETAILS, 
    icon: <Ship size="20" color='white' />
  },
  {
    label: "Logout",
    url: RouteConstant.DASHBOARD,
    icon: <LogOut size="20" />
  }
]

const MenuItems = () => {

  const user = useSelector((state) => state.auth.user);

  const role = user?.role?.role

  if(role === "SADMIN"){
    return SuperAdminMenu
  }else if(role === "CAPT"){
    return CaptainMenu
  }else{
    return ChiefEngineerMenu
  }

  return []

}

export default MenuItems