import { IconDeviceMobile, IconMail, IconMapPin, IconPencil } from '@tabler/icons-react'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setHeaderText } from '../../redux/reducers/optionsReducers'

const CompanyProfileScreen = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setHeaderText("Company Profile"))
    }, []);

    const company = useSelector((state)=> state.company.company)

  return (
    <div className='px-32'>
        <div className='profile-bg bg-center bg-cover bg-no-repeat w-full'>    
            <div class="container-fixed">
                <div class="flex flex-col items-center gap-2 py-3 lg:gap-3.5 lg:pt-5">
                    <img class="rounded-full border-3 border-success h-[100px] shrink-0" src={company?.company_logo} />
                    <div class="text-center">
                        <h2 class="text-[16px] font-bold text-black">{company?.company_name}</h2>
                    </div>
                </div>
                <div className='text-[14px] font-semibold text-center flex justify-center max-[670px]:flex-col items-center gap-4 mt-3 text-secondary'>
                    <div className="flex justify-center items-center gap-1"><IconMail size={17} /> {company?.company_email}</div>
                    <div className="flex justify-center items-center gap-1"><IconDeviceMobile size={17} /> {company?.company_mobile}</div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CompanyProfileScreen