import * as React from "react";
const EmptyImage = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    width={90}
    height={87.851}
    viewBox="0 0 90 87.851"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <path
      d="M57.136 19.757H32.884a2.088 2.088 0 0 0 -2.084 2.084v53.899l-0.278 0.085 -5.949 1.822a1.113 1.113 0 0 1 -1.388 -0.738L5.489 19.105a1.112 1.112 0 0 1 0.738 -1.388l9.167 -2.807L41.972 6.774l9.167 -2.807a1.11 1.11 0 0 1 1.388 0.737l4.523 14.775Z"
      fill="#f2f2f2"
    />
    <path
      d="M62.428 19.48 56.976 1.672A2.362 2.362 0 0 0 54.026 0.104L41.137 4.049 14.561 12.186 1.672 16.133a2.364 2.364 0 0 0 -1.568 2.95l18.632 60.858a2.364 2.364 0 0 0 2.26 1.672 2.34 2.34 0 0 0 0.691 -0.104l8.836 -2.704 0.278 -0.086v-0.29l-0.278 0.085 -8.918 2.731a2.088 2.088 0 0 1 -2.603 -1.383L0.37 19.001a2.082 2.082 0 0 1 1.383 -2.603l12.889 -3.947L41.218 4.316 54.107 0.37A2.1 2.1 0 0 1 54.72 0.278a2.088 2.088 0 0 1 1.99 1.474l5.426 17.726 0.086 0.278h0.289Z"
      fill="#3f3d56"
    />
    <path
      d="M17.048 17.763a1.254 1.254 0 0 1 -1.196 -0.884l-1.79 -5.846a1.251 1.251 0 0 1 0.83 -1.562L39.341 1.984a1.252 1.252 0 0 1 1.562 0.83l1.79 5.846a1.252 1.252 0 0 1 -0.83 1.562l-24.45 7.486a1.2 1.2 0 0 1 -0.365 0.055"
      fill="#6c63ff"
    />
    <path
      cx={190.15351}
      cy={24.95465}
      r={20}
      fill="#6c63ff"
      d="M29.204 3.468a2.778 2.778 0 0 1 -2.779 2.779A2.778 2.778 0 0 1 23.646 3.468a2.779 2.779 0 0 1 5.558 0"
    />
    <path
      cx={190.15351}
      cy={24.95465}
      r={12.66462}
      fill="#fff"
      d="M28.185 3.468a1.76 1.76 0 0 1 -1.76 1.76 1.76 1.76 0 0 1 -1.76 -1.76 1.76 1.76 0 0 1 3.52 0"
    />
    <path
      d="M83.746 80.903H36.775a1.182 1.182 0 0 1 -1.181 -1.181V23.44a1.182 1.182 0 0 1 1.181 -1.181h46.971a1.182 1.182 0 0 1 1.181 1.181v56.282a1.182 1.182 0 0 1 -1.181 1.181"
      fill="#e6e6e6"
    />
    <path
      d="M62.137 19.48H32.885a2.366 2.366 0 0 0 -2.362 2.362v56.671l0.278 -0.085v-56.586a2.088 2.088 0 0 1 2.084 -2.084h29.339Zm25.501 0H32.885a2.366 2.366 0 0 0 -2.362 2.362v63.647a2.366 2.366 0 0 0 2.362 2.362h54.753a2.366 2.366 0 0 0 2.362 -2.362V21.842a2.366 2.366 0 0 0 -2.362 -2.362m2.084 66.009a2.088 2.088 0 0 1 -2.084 2.084H32.885a2.088 2.088 0 0 1 -2.084 -2.084V21.842a2.088 2.088 0 0 1 2.084 -2.084h54.753a2.088 2.088 0 0 1 2.084 2.084Z"
      fill="#3f3d56"
    />
    <path
      d="M73.046 25.594H47.476a1.252 1.252 0 0 1 -1.251 -1.251V18.229a1.252 1.252 0 0 1 1.251 -1.251h25.57a1.252 1.252 0 0 1 1.251 1.251v6.115a1.252 1.252 0 0 1 -1.251 1.251"
      fill="#6c63ff"
    />
    <path
      cx={433.63626}
      cy={105.17383}
      r={20}
      fill="#6c63ff"
      d="M63.04 14.615a2.778 2.778 0 0 1 -2.779 2.78 2.778 2.778 0 0 1 -2.779 -2.78 2.779 2.779 0 0 1 5.558 0"
    />
    <path
      cx={433.63626}
      cy={105.17383}
      r={12.18187}
      fill="#fff"
      d="M61.954 14.615a1.692 1.692 0 0 1 -1.693 1.693 1.692 1.692 0 0 1 -1.693 -1.693 1.693 1.693 0 0 1 3.386 0"
    />
  </svg>
);
export default EmptyImage;
