import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
};

const inspectionSlice = createSlice({
  name: 'inspection',
  initialState,
  reducers: {
    setInspectionData: (state, action) => {
      state.data = action.payload;
    },
    resetInpectionData: (state) => {
      state.data = initialState.data
    },
  },
});

export const { setInspectionData, resetInpectionData } = inspectionSlice.actions;
export default inspectionSlice.reducer;