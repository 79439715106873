import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setHeaderText, setLoader } from '../../redux/reducers/optionsReducers';
import { RouteConstant } from '../../routes/RouteConstant';
import BreadCrumbs from '../../components/BreadCrumbs';
import { CheckCircle2, History, HistoryIcon, Image, PauseCircle, XCircleIcon } from 'lucide-react';
import questionQueries from '../../queries/questionQueries';
import { Button, Checkbox, Switch, Tab, Tabs } from '@nextui-org/react';
import { IconMessage } from '@tabler/icons-react';
import { Badge } from 'antd';
import CommentModal from './CommentModal';
import commentQueries from '../../queries/commentQueries';
import ProgressBar from '../../components/ProgressBar';
import Loader from '../../components/Loader';
import QuestionCommentModal from './QuestionCommentModal';
import { decode } from 'html-entities';
import QuestionDocument from '../Documents/QuestionDocument';
import { useNavigate } from 'react-router-dom';
import checklistQueries from '../../queries/checklistQueries';
import { useApi } from '../../components/ApiProvider';

const breadcrumb = [
    {
        label: "Vessel",
        path: RouteConstant.VESSEL,
        roles: ["SADMIN"]
    },
    {
        label: "Vessel Details",
        roles: ["CENG", "CAPT", "2E", "3E", "CMATE", "2M", "3M"],
        path: RouteConstant.VESSELDETAILS
    },
    {
        label: "Inspection History",
        path: RouteConstant.INSPECTIONHISTORY
    },
    {
        label: "Chapter Progress",
        path: RouteConstant.CHAPTERPROGRESS
    },
    {
        label: "Question Progress",
        path: RouteConstant.QUESTIONPROGRESS
    },
    {
        label: "Question Detail"
    }
]

const QuestionDetailScreen = () => {

    const navigate = useNavigate()

    const question = useSelector((state)=> state.master.question)
    const chapter = useSelector((state)=> state.master.chapter)
    const inspection = useSelector((state)=> state.master.inspection)
    const master = useSelector((state)=> state.master)
    const loader = useSelector((state)=> state.options.loader)
    const dispatch = useDispatch()
    const [percentage, setPercentage] = useState(0)

    const [result, setResult] = useState({})

    const { apiData } = useApi()

    useEffect(() => {
        dispatch(setLoader(true))
        dispatch(setHeaderText(`Chapter : ${chapter.name}`))
    }, []);

    const questionView = questionQueries.useQuestionViewMutationInspection(
        (res)=>{
            dispatch(setLoader(false))
            setResult(res?.data)
            percentageOfCheck(res?.data)
        }
    )

    useEffect(() => {
        questionView.mutateAsync({id: question.id, inspection: inspection})
    }, [apiData]);

    const percentageOfCheck = (data)=>{
        const checklist = data?.checklists
        let totalCheckList = 0
        checklist?.map(element=> {
            if(!element.not_applicable_checklist){
                totalCheckList += 1
            }
        })
        const success = checklist?.filter(item=> (item?.checklistaction?.action === "YES" || item?.checklistaction?.action === "NO") && !item.not_applicable_checklist)
        const percentage = (success?.length / totalCheckList) * 100
        if(isNaN(percentage)){
            setPercentage(0)
        }else{
            setPercentage(percentage)
        }
    }

    if(loader){
        return <Loader />
    }

    return (
        <Fragment>

            <div className='flex justify-between mb-4 items-center'>
                <BreadCrumbs data={breadcrumb} />
                <ProgressBar data={percentage} />
            </div>

            <div className='text-[13px] font-[600]'>

                <div className="flex items-start justify-between border border-questionDetailBorder rounded-md p-[19px] bg-white card-shadow">
                    <div className="flex items-start gap-4">
                        <div className="bg-gradient-to-r from-[#8BC34A] to-green-400 min-w-[40px] w-[40px] h-[40px] rounded-full flex items-center justify-center text-[14px] text-white">
                            {chapter?.chapter_no}.{result?.section}.{result?.sub_section}
                        </div>
                        <div>
                            {decode(result?.question)}
                        </div>
                        <div />
                    </div>
                </div>

                <div className="flex items-start justify-between border border-questionDetailBorder rounded-md p-[19px] mt-4 bg-white card-shadow">
                    <div className="flex flex-col gap-3">
                        <div className="flex flex-col items-start">
                            <div>Short Question Text</div>
                            <div className="font-[400]">{result?.short_question}</div>
                        </div>
                        <div className="flex flex-col items-start">
                            <div>ROVIQ Sequence</div>
                            <div className="font-[400]">{result?.roviq}</div>
                        </div>
                        <div className="flex flex-col items-start">
                            <div>Objective</div>
                            <div className="font-[400]" dangerouslySetInnerHTML={{ __html: result?.objectives }} />
                        </div>
                        <div className="flex flex-col items-start">
                            <div>Vessel</div>
                            <div className="font-[400]">{master?.header}</div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-3">
                        <div className="flex items-center justify-center">
                            <Badge count={result?.question_comments?.length}>
                                <QuestionCommentModal />
                            </Badge>
                        </div>
                        <div className="flex items-center justify-center">
                            <Button onClick={()=> navigate(RouteConstant.DOCUMENTS)} className="flex items-center justify-center bg-primary text-white font-semibold text-[12px]">
                                Document
                            </Button>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col items-start justify-between border border-questionDetailBorder rounded-md mt-4 bg-white card-shadow">
                    <div className="bg-detailCardHeader flex items-center text-[16px] pl-4 text-white h-12 w-full rounded-md">
                        Checklist
                    </div>
                    <div className="p-5 w-full">
                        <div className="flex gap-6 justify-between items-center bg-[#eaeaea] rounded-md p-3 w-full">
                            <div className="w-full"></div>
                            <div className='flex'>
                                <div className="w-[160px]">Unhealthy / Healthy</div>
                                <div className="w-[80px]">Comments</div>
                                <div className="w-[80px] flex justify-center">N/A</div>
                            </div>
                        </div>
                        {
                            result?.checklists?.map((check, index)=>(
                                <CheckList index={index} key={index} check={check} result={result} />
                            ))
                        }
                    </div>
                </div>


                <div className="flex flex-col items-start justify-between border border-questionDetailBorder rounded-md mt-4 pb-4 bg-white card-shadow">
                    <div className="bg-inspectionHeader flex items-center text-[16px] pl-4 text-white h-12 w-full rounded-md">
                        Inspection
                    </div>
                    <div className="flex w-full flex-col mt-2">
                        <Tabs variant='underlined' aria-label="Options">
                            <Tab key="human" title="Human">
                                <Human data={result?.question_inspections} result={result} />
                            </Tab>
                            <Tab key="process" title="Process">
                                <Process data={result?.question_inspections} result={result} />
                            </Tab>
                            <Tab key="hardware" title="Hardware">
                                <Hardware data={result?.question_inspections} result={result} />
                            </Tab>
                        </Tabs>
                    </div>
                </div>

            </div>

        </Fragment>
    )
}

export default QuestionDetailScreen

const CheckList = ({check, index, result})=>{

    const inspection = useSelector((state)=> state.master.inspection)

    const { setApiDataAndNotify } = useApi()

    const checkListTypeItems = [
        {type: "PROCESS", label: "PR", color: "#FF6868"},
        {type: "HUMAN", label: "HU", color: "#03C988"},
        {type: "HARDWARE", label: "HW", color: "#3081D0"},
    ]

    const checkListType = (item)=>{
        const type = checkListTypeItems?.find(i => i.type === item)
        if(type){
            return type
        }
    }

    const createComment = commentQueries.useCommentMutationCreate(
        (res)=>{
            setApiDataAndNotify(res)
        }
    )

    const checklistAction = checklistQueries.useChecklistActionMutation(
        (res)=>{
            setApiDataAndNotify(res)
        }
    )

    const checklistNotApplicable = checklistQueries.useChecklistNotApplicableMutation(
        (res)=>{
            setApiDataAndNotify(res)
        }
    )

    const handleChecklistAction = (action) => {
        checklistAction.mutateAsync({check_id: check?.id, action: action, inspection_id: inspection})
    }

    const handleNotApplicable = (e) => {
        const item = e?.target?.checked
        console.log(item)
        checklistNotApplicable.mutateAsync({check_id: check?.id, inspection_id: inspection, action: item ? "YES" : "NO"})
    }


    return(
        <div key={index} className={`flex gap-6 justify-between items-center p-3 w-full ${index+1 < result?.checklists?.length &&  "border-b"} border-questionDetailBorder`}>
            <div className='text-[9px] min-w-[25px] w-[25px] h-[25px] flex items-center justify-center text-white rounded-full p-1' style={{background: checkListType(check?.type)?.color}}>{checkListType(check?.type)?.label}</div>
            <div className="w-full">{check?.checklist}</div>
            <div className='flex'>
                <div className="w-[160px] flex justify-center">
                    <div 
                    className={`${checkAction(check) === "PENDING" ? "bg-[#f8db41]":checkAction(check) === "NO" ? "bg-[#e32f2c]":"bg-[#6eb347]"} shadow-xl flex items-center px-1 gap-2 rounded-full`}
                    >
                        {
                            CheckListActionItems.map((item, index)=>{
                                return(
                                    <button
                                    disabled={check.not_applicable_checklist}
                                    onClick={()=> handleChecklistAction(item?.value)}
                                    style={{color: checkAction(check) === item?.value ? item?.color : "#fff" }}
                                    className={`${checkAction(check) === item?.value ? "bg-white":"bg-transparent"} ${checkAction(check) === item?.value ? "text-["+item?.color+"]" : "text-white"} flex items-center justify-center p-1 rounded-full`}
                                    key={index}>
                                        {item?.icon}
                                    </button>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="w-[80px] flex justify-center">
                    {
                        check?.not_applicable_checklist ?
                        <button disabled className="w-[30px] h-[30px] flex items-center justify-center rounded-full ring-2 ring-blue-500 opacity-70">
                            <IconMessage size="16" className='text-iconColor' />
                        </button>:
                        <Badge count={check?.comments?.length}>
                            <CommentModal data={check?.comments} action={check?.checklistaction} checklist={check?.id} createComment={createComment} />
                        </Badge>
                    }
                </div>
                <div className="w-[80px] flex justify-center">
                    <Checkbox onChange={handleNotApplicable} isSelected={check.not_applicable_checklist} />
                </div>
            </div>
        </div>
    )
}

const CheckListActionItems = [
    {value:"PENDING", icon: <PauseCircle size="16" />, color: "#f8db41"},
    {value:"NO", icon: <XCircleIcon size="16" />, color: "#e32f2c"},
    {value:"YES", icon: <CheckCircle2 size="16" />, color: "#6eb347"},
]

const checkAction =(item)=>{
    if(!item?.checklistaction){
        return "PENDING"
    }else if(item?.checklistaction?.action === "YES"){
        return "YES"
    }else if(item?.checklistaction?.action === "NO"){
        return "NO"
    }
}

const ratingItems = [
    {value:4, label:"😊"},
    {value:3, label:"😐"},
    {value:2, label:"😮"},
    {value:1, label:"🙁"},
]

const inspectFind =(item, type, type2)=>{
    if(type2 === "BINARY"){
      const rating = item?.find(i => i.type === type)
      if(rating){
        return "YES"
      }
      return "NO"
    }else{
        if(item?.length > 0){
          const rating = item?.find(i => i.type === type)?.rating
          return rating
        }
    }
}


const Human =({data})=>{
    return(
        <div className="px-3">
            <div className="w-full flex justify-between items-center gap-4">
                <div className="flex gap-2">
                    <div className="bg-primary text-white min-w-[20px] h-[20px] flex items-center justify-center rounded-full">
                        1
                    </div>
                    <div>
                        Were the Master and officers familiar with the company procedures
                        for the use of the inert gas system, gas system been with ISGOTT
                        guidance, with except when it was necessary to be gas-free for
                        entry?
                    </div>
                </div>
                {/* <div className="text-[35px] gap-4 flex max-[547px]:flex-col items-center">
                    {
                        ratingItems?.map((item, index)=>(
                            <div key={index} style={{fontSize: inspectFind(data, "HUMAN", "GRADED") === item?.value ? '35px':'25px'}}>{item?.label}</div>
                        ))
                    }
                </div> */}
            </div>
            <div className="bg-gray-200 mt-4 p-3 rounded-lg flex flex-col gap-2">
                <div className="flex items-center gap-3">
                    <div className="text-[18px]">😊</div>
                    <div>
                    Execution of task exceeded normal expectations – mandatory tagging
                    of PIF with a mandatory supporting comment to provide context
                    </div>
                </div>
                <div className="flex items-center gap-3">
                    <div className="text-[18px]">😐</div>
                    <div>
                    Execution of task was as expected – no requirement for comment
                    </div>
                </div>
                <div className="flex items-center gap-3">
                    <div className="text-[18px]">😮</div>
                    <div>
                    Execution of task was largely as expected – Mandatory tagging of
                    PIF with a mandatory supporting comment to provide context
                    </div>
                </div>
                <div className="flex items-center gap-3">
                    <div className="text-[18px]">☹️</div>
                    <div className="text-red-500">
                    Execution of task was not as expected – Procedure and/or document
                    deficient – Negative observation required in the negative
                    observation module AND The most appropriate PIF(s) affecting the
                    performance of the task sh0uld be tagged.
                    </div>
                </div>
            </div>
            <div className="mt-4 flex gap-2 flex-wrap text-white font-normal text-[11px]">
                <div className="bg-primary rounded-[4px] px-3 py-1">Org</div>
                <div className="bg-primary rounded-[4px] px-3 py-1">Physical</div>
                <div className="bg-primary rounded-[4px] px-3 py-1">Social</div>
                <div className="bg-primary rounded-[4px] px-3 py-1">Psychological</div>
            </div>
        </div>
    )
}

const Process =({data, result})=>{
    return(
        <div className="px-3">
            <div className="w-full flex justify-between items-center">
                <div className="flex gap-2">
                    <div className="bg-primary text-white min-w-[20px] h-[20px] flex items-center justify-center rounded-full">
                    1
                    </div>
                    <div>Declare through the pre-inspection questionnaire?</div>
                </div>
                {/* <div className="text-[35px] gap-4 flex max-[547px]:flex-col">
                    {
                        result?.hardware === "GRADED" ?
                        <Fragment>
                            {
                                ratingItems?.map((item, index)=>(
                                    <div key={index} style={{fontSize: inspectFind(data, "PROCESS", "GRADED") === item?.value ? '35px':'25px'}}>{item?.label}</div>
                                ))
                            }
                        </Fragment>:
                        <Switch
                        isSelected={inspectFind(data, "PROCESS", "BINARY") === "YES" ? true: false}
                        classNames={{
                            wrapper: "bg-red-600",
                            label: "bg-green-600"
                        }}
                        color='success'
                        />
                    }
                </div> */}
            </div>
            {
                result?.process === "BINARY" ?
                <BinaryResponse/>
                :
                <GradedResponse/>
            }
      </div>
      
    )
}

const Hardware =({data, result})=>{
    return(
        <div className="px-3">
            <div className="w-full flex justify-between items-center">
                <div className="flex gap-2">
                    <div className="bg-primary text-white min-w-[20px] h-[20px] flex items-center justify-center rounded-full">
                        1
                    </div>
                    <div>
                        Were the Master and officers familiar with the company procedures for
                        the use of the inert gas system, gas system been with ISGOTT guidance,
                        with except when it was necessary to be gas-free for entry?
                    </div>
                </div>
                {/* <div className="text-[35px] gap-4 flex max-[547px]:flex-col items-center">
                    {
                        result?.hardware === "GRADED" ?
                        <Fragment>
                            {
                                ratingItems?.map((item, index)=>(
                                    <div key={index} style={{fontSize: inspectFind(data, "HARDWARE", "GRADED") === item?.value ? '35px':'25px'}}>{item?.label}</div>
                                ))
                            }
                        </Fragment>:
                        <Switch
                        isSelected={inspectFind(data, "HARDWARE", "BINARY") === "YES" ? true: false}
                        classNames={{
                            wrapper: "bg-red-600",
                            label: "bg-green-600"
                        }}
                        color='success'
                        />
                    }
                </div> */}
            </div>
            {
                result?.hardware === "BINARY" ?
                <BinaryResponse/>
                :
                <GradedResponse/>
            }
        </div>
    )
}

const BinaryResponse =()=>{
    return(
        <div className="bg-gray-200 mt-4 p-3 rounded-lg flex flex-col gap-2">
            <div className="flex items-center gap-3">
                <div className="bg-red-500 min-w-[8px] h-[8px] rounded-full" />
                <div>Free from obvious deterioration of deficiency</div>
            </div>
            <div className="flex items-center gap-3">
                <div className="bg-green-500 min-w-[8px] h-[8px] rounded-full" />
                <div>
                Observable or detectable deficiency (include inspector photograph)
                </div>
            </div>
        </div>
    )
}

const GradedResponse =()=>{
    return(
        <div className="bg-gray-200 mt-4 p-3 rounded-lg flex flex-col gap-2">
            <div className="flex items-center gap-3">
            <div className="text-[18px]">😊</div>
            <div>Free from obvious deterioration or deficiency</div>
            </div>
            <div className="flex items-center gap-3">
            <div className="text-[18px]">😐</div>
            <div>
                Slight superficial deterioration – No supporting comments necessary
            </div>
            </div>
            <div className="flex items-center gap-3">
            <div className="text-[18px]">😮</div>
            <div>
                Slight superficial deterioration – With description (mandatory comment
                and/or inspector photograph)
            </div>
            </div>
            <div className="flex items-center gap-3">
            <div className="text-[18px]">☹️</div>
            <div className="text-red-500">
                Observable or detectable deficiency – Negative observation required in
                the negative observation module and inspector photograph
            </div>
            </div>
        </div>
    )
}