import { Eye, PlusCircle } from 'lucide-react'
import React, { Fragment, useEffect, useState } from 'react'
import vesselQueries from '../../queries/vesselQueries'
import { VesselFormData } from '../../data/VesselForm'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { removeMaster, setMaster, setVessel } from '../../redux/reducers/masterReducers'
import PaginationComponent from '../../components/PaginationComponent'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { RouteConstant } from '../../routes/RouteConstant'
import { setHeaderText, setLoader } from '../../redux/reducers/optionsReducers'
import Loader from '../../components/Loader'
import { IconCheckupList } from '@tabler/icons-react'
import ProgressBar from '../../components/ProgressBar'
import DrawerComponent from '../../components/DrawerComponent'
import { useApi } from '../../components/ApiProvider'

const VesselScreen = () => {

  const [result, setResult] = useState([])
  const dispatch = useDispatch()
  const currentPage = useSelector((state)=> state.pagination.currentPage)
  const loader = useSelector((state)=> state.options.loader)
  
  const {apiData, setApiDataAndNotify} = useApi()

  const navigate = useNavigate()
  
  useEffect(() => {
    dispatch(setLoader(true))
    dispatch(setHeaderText("Vessel"))
    dispatch(removeMaster())
  }, []);

  const vesselList = vesselQueries.useVesselMutationList(
    (res)=>{
      dispatch(setLoader(false))
      setResult(res)
    }
  )

  useEffect(() => {
    vesselList.mutateAsync({size: 10, page: currentPage})
  }, [currentPage, apiData]);

  const createMaster = vesselQueries.useVesselMutationCreate(
    (res)=>{
      if(res?.message === "Error"){
        {res?.error?.map((error, index) => (
          Object?.values(error).map((errorMessage, innerIndex) => (
              toast.error(errorMessage)
            ))
        ))}
        return
      }
      if(res?.message=== "Success"){
          toast.success("Successfully created vessel")
          setApiDataAndNotify(res)
      }
    }
  )

  const handleDetails =(item)=>{
    dispatch(setMaster({
        id: item.id,
        header: ""
      }
    ))
    dispatch(setVessel({
      id: item?.id,
      name: item?.name,
      imo: item?.imo,
      built: item?.built,
      type: item?.type,
      dwt: item?.dwt,
      owner: item?.owner,
    }))
    dispatch(setHeaderText(`Vessel : ${item?.name}`))
    navigate(RouteConstant.VESSELDETAILS)
  }

  const handleInspection =(item)=>{
    dispatch(setMaster({
        id: item.id,
        header: item.name
      }
    ))
    dispatch(setVessel({
      id: item?.id,
      name: item?.name,
      imo: item?.imo,
      built: item?.built,
      type: item?.type,
      dwt: item?.dwt,
      owner: item?.owner,
    }))
    dispatch(setHeaderText(`Vessel : ${item?.name}`))
    navigate(RouteConstant.INSPECTIONHISTORY)
  }

  if(loader){
    return <Loader />
  }

  return (
    <Fragment>
      <div className="w-full max-[410px]:p-3  text-[14px]">
        <div className="">
          <div className="flex justify-end">
            <DrawerComponent inputFields={VesselFormData}  entityForm={createMaster} btnName={'Add Vessel'} header={'Add New Vessel'} />
          </div>
          {
              result?.data?.items?.map((item, index) =>(
              <div className="flex flex-col gap-6 my-4" key={index}>
                <div className="ship-card border-vesselOrange bg-white card-shadow">
                  <div className="w-full flex flex-col gap-6">
                    <div className="text-[20px] flex justify-between max-[768px]:flex-col font-[600]">
                      <div className='max-[768px]:text-center'>
                        {item?.name}
                      </div>
                      <div className='max-[768px]:flex justify-center'>
                          {progressFind(item?.vessel_questions, item?.inspection_histories)}
                      </div>
                    </div>
                    <div className="grid grid-cols-12 max-[1601px]:grid-cols-10 max-[965px]:grid-cols-8 max-[577px]:grid-cols-5 gap-y-6 w-full font-[600] text-[12px]">
                      <div className="col-span-3 max-[965px]:col-span-4">
                        <div className="text-[#737373] text-[13px]">
                          Last Updated by
                        </div>
                        <div className="text-[13px]">
                          {item?.updatedBy.fname} {item?.updatedBy.lname} @ {moment(item?.createdAt).format('DD-MM-YYYY')}
                        </div>
                      </div>
                      <div className="col-span-1 max-[965px]:col-span-3">
                        <div className="text-[#737373]">
                          IMO No.
                        </div>
                        <div>
                          {item?.imo_no}
                        </div>
                      </div>
                      <div className="col-span-2 max-[965px]:col-span-2">
                        <div className="text-[#737373]">
                          Built
                        </div>
                        <div>
                          {item?.built}
                        </div>
                      </div>
                      <div className="col-span-2 max-[965px]:col-span-3">
                        <div className="text-[#737373]">
                          Type
                        </div>
                        <div>
                          {item?.type}
                        </div>
                      </div>
                      <div className="col-span-1 max-[965px]:col-span-2">
                        <div className="text-[#737373]">
                          DWT
                        </div>
                        <div>
                          {item?.dwt}
                        </div>
                      </div>
                      <div className="col-span-3 justify-end max-[1601px]:justify-start max-[1722px]:col-span-3 max-[1222px]:col-span-4 max-[607px]:col-span-5 flex max-[340px]:flex-col gap-2">
                        <button onClick={()=>handleDetails(item)} className="flex gap-2 items-center bg-primary text-white justify-center px-4 py-2 rounded-lg">
                          <Eye size="16" />
                          View
                        </button>
                        <button onClick={()=>handleInspection(item)} className="flex gap-2 items-center bg-primary text-white justify-center px-4 py-2 rounded-lg max-[356px]:min-w-[150px]">
                          <IconCheckupList size="16" />
                          View Assessment
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          ))}

          <PaginationComponent result={result} />

        </div>
      </div>
    </Fragment>
  )
}

export default VesselScreen

const progressFind =(data, data2)=>{
  let checklists = []
  let checkListDone = []
  let notApplicable = []
  data?.map((item)=>{
    const question = item?.question
    if(question?.chapter?.status === "ACTIVE"){
      question?.checklists?.map(checklist=>{
        if(checklist?.status === "ACTIVE"){
          checklists.push(checklist.id)
        }
      })
    }
  })

  if(data2?.length > 0){
    const tempData = data2[0]
    tempData?.checklistactions?.map(item => checkListDone.push(item.check_id))
    tempData?.not_applicable_checklists?.map(item => {
      notApplicable.push(item.check_id)
    })
  }

  const totalChecklist = checklists.filter(item => !notApplicable.includes(item)).length;
  const success = checklists.filter(item => checkListDone.includes(item)).length;

  let percentage = totalChecklist === 0 ? 0 : (success / totalChecklist) * 100

  const roundPercentage = Math.round(percentage)
  if(isNaN(percentage)){
    return <ProgressBar data="0" />
  }else{
    return <ProgressBar data={roundPercentage} />
  }
}