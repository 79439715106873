import { useMutation } from "react-query";
import checklistService from "../services/checklistService";

const useChecklistActionMutation = (onData) => {
    return useMutation(checklistService.checklistAction, {
        onSuccess: (data) => onData(data),
    });
};

const useChecklistNotApplicableMutation = (onData) => {
    return useMutation(checklistService.checklistNotApplicable, {
        onSuccess: (data) => onData(data),
    });
};

const checklistQueries = {
    useChecklistActionMutation,
    useChecklistNotApplicableMutation
};

export default checklistQueries;
