import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const TextEditor =({control, name})=> {

  const modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  return (
    <div>
        <Controller
        name={name}
        control={control}
        render={({ field }) => (
            <ReactQuill
            className='h-[250px] max-h-[250px]' 
            theme="snow"
            {...field}
            modules={modules}
            formats={formats}
            />
        )}
        /> 
    </div>
  )
}

export default TextEditor