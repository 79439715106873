export const RouteConstant = {
    ROOT: '/',
    LOGIN: "/",
    DASHBOARD: "/dashboard",
    QUESTIONFORM: "/question_form",
    QUESTIONLIST: "/questions",
    VESSEL:"/vessel",
    CHAPTER:"/chapter",
    PROFILE:"/company_profile",
    USERS: "/users",
    VESSELDETAILS:"/vessel_detail",
    CHAPTERPROGRESS:"/chapter_progress",
    QUESTIONPROGRESS:"/question_progress",
    INSPECTIONHISTORY:"/inspection_history",
    QUESTIONDETAIL:"/question_detail",
    OCIMFVESSEL:"/ocimf_vessel",
    INSPECTIONREPORT: "/inspection_report",
    ASSIGNLIST: "/assigning_list",
    INSPECTIONOVERVIEW: "/inspection_overview",
    DOCUMENTS: "/documents",
}