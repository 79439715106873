import React, { Fragment, useEffect, useState } from 'react'
import QuestionDocument from './QuestionDocument'
import { useSelector } from 'react-redux'
import documentQueries from '../../queries/documentQueries'
import toast from 'react-hot-toast'
import { IconTrash } from '@tabler/icons-react'
import { fileImages, fileTypes } from '../../utils/filetypes'
import { useApi } from '../../components/ApiProvider'
import { X } from 'lucide-react'
import { Image } from 'antd'
import BreadCrumbs from '../../components/BreadCrumbs'
import { RouteConstant } from '../../routes/RouteConstant'
import Empty from '../../components/Empty'

const breadcrumb = [
    {
        label: "Vessel",
        path: RouteConstant.VESSEL,
        roles: ["SADMIN"]
    },
    {
        label: "Vessel Details",
        roles: ["CENG", "CAPT", "2E", "3E", "CMATE", "2M", "3M"],
        path: RouteConstant.VESSELDETAILS
    },
    {
        label: "Inspection History",
        path: RouteConstant.INSPECTIONHISTORY
    },
    {
        label: "Chapter Progress",
        path: RouteConstant.CHAPTERPROGRESS
    },
    {
        label: "Question Progress",
        path: RouteConstant.QUESTIONPROGRESS
    },
    {
        label: "Question Detail",
        path: RouteConstant.QUESTIONDETAIL
    },
    {
        label: "Documents"
    }
]


const DocumentScreen = () => {

    const [result, setResult] = useState([])
    const [loader, setLoader] = useState(true)
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const vessel = useSelector((state)=> state.master.vessel)
    const question = useSelector((state)=> state.master.question)

    const { apiData } = useApi();

    const documentView = documentQueries.useDocumentMutationList(
        (res)=>{
            setLoader(false)
            setResult(res?.data)
        }
    )

    useEffect(() => {
        documentView.mutateAsync({question_id: question.id, vessel_id: vessel.id})
    }, [apiData]);

    const convertLineBreaks = (text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;

        return text?.split('\n')?.map((line, index) => (
            <Fragment key={index}>
                {line.split(urlRegex).map((part, i) => (
                    urlRegex.test(part) ? (
                        <div className="overflow-hidden">
                            <a className='text-blue-700' key={i} href={part} target="_blank" rel="noopener noreferrer">
                                {part?.slice(0, 100) + (part.length > 100 ? '...' : '')}
                            </a>
                        </div>
                    ) : (
                        <Fragment key={i}>{part}</Fragment>
                    )
                ))}
                <br />
            </Fragment>
        ));
    };

    const onPreview = async (file) => {
        
        let src = file;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }

        setPreviewOpen(true)
        setPreviewImage(src)

    };

    const deleteFile = documentQueries.useDocumentMutationDelete(
        (res)=>{
            if(res?.message === "success"){
                documentView.mutateAsync({question_id: question.id, vessel_id: vessel.id})            
                toast.success('File deleted successfully');
            }
        }
    )

    const handleRemove = async(id)=>{
        deleteFile.mutateAsync(id)
    }

  return (
    <div>

        <div className='flex justify-between mb-4 items-center'>
            <BreadCrumbs data={breadcrumb} />
        </div>

        <div className='w-full flex justify-end'>
            <QuestionDocument />
        </div>

        <Image
        preview={{
        onVisibleChange: () => setPreviewOpen(false),
        visible: previewOpen,
        src: previewImage,
        closeIcon:  <div onClick={()=>setPreviewOpen(false)}>
                        <X size="16" />
                    </div>
        }}/>

        <div className="text-[12px] font-semibold mt-2">
            {result?.length > 0 ?
                <div className="flex flex-col gap-4">
                    {
                        result?.map((item, index)=>(
                            <div key={index} className="w-full flex flex-col px-4 py-6 gap-4 relative bg-white rounded-xl border border-gray-200">
                                <div className="w-full flex max-[550px]:flex-col justify-between gap-3">
                                    <div className="flex gap-4">
                                        <div className="bg-primary text-white rounded-full h-6 w-6 min-w-[24px] flex justify-center items-center">
                                            {index+1}
                                        </div>
                                        <div>
                                            {item?.title && <div className="w-full text-[13px]">{convertLineBreaks(item?.title)}</div>}
                                            {item?.location && <div className="w-full font-medium">{item?.location}</div>}
                                            {
                                                item?.question_document_files?.length > 0 &&
                                                <div className="w-full flex flex-col gap-3 mt-4">
                                                    <div className="flex gap-4 flex-wrap">
                                                    {item?.question_document_files?.map((item, index) => (
                                                        <UploadedImagePreview key={index} item={item} index={index} onPreview={onPreview} documentView={documentView} />   
                                                    ))}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className='flex justify-end'>
                                        <button onClick={()=>{handleRemove(item.id)}} className=" bg-rose-500 shadow-lg rounded-full h-10 w-10 min-w-[40px] flex justify-center items-center">
                                            <IconTrash size={20} color="white" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>:
                <Empty/>
            }
        </div>




    </div>
  )
}

export default DocumentScreen



const UploadedImagePreview =({item, key, onPreview})=> {

    const [blur, setBlur] = useState(false);

    const allowedImageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg', 'bmp', 'tiff', 'heic', 'ico'];
    const allowedVideoExtensions = ['mp4', 'mkv', 'avi', 'mov', 'wmv', 'webm', 'mpeg', 'mpg', 'm4v'];


    const isImageFile = (filename) => {
        const ext = filename.split('.').pop().toLowerCase();
        return allowedImageExtensions.includes(ext);
    };

    const isVideoFile = (filename) => {
        const ext = filename.split('.').pop().toLowerCase();
        return allowedVideoExtensions.includes(ext);
    };

    function findCategoryByFileType(mimeType) {
        for (const [category, types] of Object.entries(fileTypes)) {
            if (types.includes(mimeType)) {
                return fileImages?.[category];
            }
        }
        return <img src="https://images.vexels.com/media/users/3/276661/isolated/preview/614fa2f6000e812cb013b82d5ed0eb21-blue-folder-squared.png" className="w-[100px] h-[100px]" />;
    }

    const previewFile = async(item)=>{
        if(item?.filetype === "application/pdf" || item?.filetype === "text/html"){
            const response = await fetch(process.env.REACT_APP_BASE_URL+`/api/fileupload/documents/${item?.filename}`);
            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
        }else{
            toast.error("Preview not available")
        }
    }

    const downloadFile = async(item)=>{
        window.open(process.env.REACT_APP_BASE_URL+`/api/fileupload/documents/${item?.filename}`, '_blank');
    }

    return(
        <Fragment>
            {
                !isImageFile(item?.filename) && !isVideoFile(item?.filename) ?
                <div
                onMouseEnter={()=>setBlur(true)}
                onMouseLeave={()=>setBlur(false)}
                key={key} 
                className="w-[250px] h-[150px] min-w-[250px] min-h-[150px] rounded-xl bg-gray-50 border border-gray-200 shadow-md relative"
                >
                <div
                style={{filter: blur ? "blur(5px)" : "none"}}
                className="w-full h-full flex flex-col gap-2 text-center justify-center items-center"
                >
                    <div className="w-[100px] h-[100px] flex items-center justify-center">
                        {findCategoryByFileType(item?.filetype)}
                    </div>
                    <div className="text-[12px] font-semibold">{(item?.filename)?.slice(0, 30)} {item?.name?.length > 30 ? "..." : ""}</div>
                </div>
                {
                    blur &&
                    <Fragment>
                        {(item?.filetype === "application/pdf" || item?.filetype === "text/html") &&
                        <button
                        onClick={()=>previewFile(item)}
                        className="absolute top-[60px] left-[30px] rounded-xl text-[12px] font-semibold text-primary bg-white shadow-lg px-3 py-2">
                            Preview
                        </button>
                        }
                        <button
                        className={`absolute ${(item?.filetype === "application/pdf" || item?.filetype === "text/html") ? "top-[60px] right-[30px]" : "top-[60px] left-[80px]"} rounded-xl text-[12px] font-semibold text-primary bg-white shadow-lg px-3 py-2`}
                        onClick={()=>downloadFile(item)}
                        >
                            Download
                        </button>
                    </Fragment>
                }
                </div>:
                isImageFile(item?.filename) ?
                <div
                onMouseEnter={()=>setBlur(true)} 
                onMouseLeave={()=>setBlur(false)}
                key={key} 
                className="relative"
                >
                    <img
                    src={process.env.REACT_APP_BASE_URL+`/api/fileupload/documents/${item?.filename}`} 
                    alt={item.name}
                    style={{filter: blur ? "blur(5px)" : "none"}}
                    className="w-[250px] h-[150px] min-w-[250px] min-h-[150px] rounded-xl object-cover" 
                    />
                    {
                        blur &&
                        <Fragment>

                            <button
                            onClick={()=>onPreview(process.env.REACT_APP_BASE_URL+`/api/fileupload/documents/${item?.filename}`)}
                            className="absolute top-[60px] left-[30px] rounded-xl text-[12px] font-semibold text-primary bg-white shadow-lg px-3 py-2">
                                Preview
                            </button>
                            <button
                            className="absolute top-[60px] right-[30px] rounded-xl text-[12px] font-semibold text-primary bg-white shadow-lg px-3 py-2"
                            onClick={()=>downloadFile(item)}
                            >
                                Download
                            </button>
                        </Fragment>
                    }
                </div>:
                <div
                onMouseEnter={()=>setBlur(true)}
                onMouseLeave={()=>setBlur(false)}
                key={key}
                className="w-[250px] h-[150px] min-w-[250px] min-h-[150px] rounded-xl bg-gray-50 border border-gray-200 shadow-md relative"
                >
                    <video className='w-[250px] h-[150px] object-contain' controls>
                        <source src={process.env.REACT_APP_BASE_URL+`/api/fileupload/documents/${item?.filename}`} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            }
        </Fragment>
    )
}