import React from 'react'
import { Controller } from 'react-hook-form'

const TextArea = ({control, items, name}) => {

    const item = items?.find(element=>element.name=== name)

    if(!item){
        return  
    }

  return (
        <Controller
        name={item?.name}
        control={control}
        render={({ field }) => (
            <textarea
            className='h-[145px] w-full py-3 px-5 rounded-lg focus-within:outline-none border-2 border-[#dfdfdf] placeholder:text-[12px]'             
            type={item?.type ? item?.type : 'text'}
            placeholder={item?.placeholder? item?.placeholder :item?.label}
            name={item?.name}
            {...field}
            />
        )}
        />  
    )
}

export default TextArea