import apiRequest from '../apis/api-request';
import { selectAuthToken } from '../redux/reducers/authReducers';
import { store } from '../redux/store';

const checklistService = {

    checklistAction: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "POST",
            url: `/api/checklistaction`,
            data: data,
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    },

    checklistNotApplicable: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "POST",
            url: `/api/checklistaction/notapplicable`,
            data: data,
            headers: {
                Authorization: "Bearer " + token,
            },
        });
    }

};

export default checklistService;