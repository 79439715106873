
import React from "react";
import { Routes, Route, } from "react-router-dom";  
import { RouteConstant } from "./RouteConstant";
import PageLayout from "../screens/Layout/PageLayout";
import LoginScreen from "../screens/Login/LoginScreen";
import VesselScreen from "../screens/Vessel/VesselScreen";
import VesselDetailsScreen from "../screens/Vessel/VesselDetailsScreen";
import AssignedList from "../screens/User/AssignedList";
import InspectionHistory from "../screens/InspectionHistory/InspectionHistory";
import ChapterProgress from "../screens/ChapterProgress/ChapterProgress";
import QuestionProgress from "../screens/QuestionProgress.js/QuestionProgress";
import QuestionDetailScreen from "../screens/QuestionProgress.js/QuestionDetailScreen";
import PdfGenerator from "../screens/InspectionHistory/PdfGenerator";
import InpectionOverview from "../screens/InpectionOverview/InspectionOverview";
import DocumentScreen from "../screens/Documents/DocumentScreen";

const CaptainRoutes = () => {
  return (
    <Routes>
        <Route exact path={RouteConstant.ROOT} element={ <LoginScreen /> } />
        <Route path={RouteConstant.ROOT} element={<PageLayout />}>
            <Route path={RouteConstant.VESSELDETAILS} element={ <VesselDetailsScreen /> } />
            <Route path={RouteConstant.ASSIGNLIST} element={ <AssignedList /> } />
            <Route path={RouteConstant.INSPECTIONHISTORY} element={ <InspectionHistory /> } />
            <Route path={RouteConstant.CHAPTERPROGRESS} element={ <ChapterProgress /> } />
            <Route path={RouteConstant.QUESTIONPROGRESS} element={ <QuestionProgress /> } />
            <Route path={RouteConstant.QUESTIONDETAIL} element={ <QuestionDetailScreen /> } />
            <Route path={RouteConstant.INSPECTIONREPORT} element={ <PdfGenerator /> } />
            <Route path={RouteConstant.INSPECTIONOVERVIEW} element={ <InpectionOverview /> } />
            <Route path={RouteConstant.DOCUMENTS} element={ <DocumentScreen /> } />
        </Route>
    </Routes>
  );
}

export default CaptainRoutes;