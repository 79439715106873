import React, { Fragment } from 'react'
import { Controller } from 'react-hook-form'
import {ValidationErrorMaster} from './validationError'

const InputMaster = ({register, item, errors}) => {

  return (
    <Fragment>
        <div className="text-[12px] h-4 font-[500]">{item?.label} {item?.required &&<span className="text-[red]">*</span>}</div>
        <input
        className="w-full h-[40px] border rounded-[7px] px-3 mt-[1px] text-[13px]"   
        type={item?.type ? item?.type : 'text'}
        placeholder={item?.placeholder? item?.placeholder :item?.label}
        {...register(item?.name)}
        name={item?.name}
        />
        <ValidationErrorMaster error={errors?.[item?.name]} />
    </Fragment>
    )
}

export default InputMaster