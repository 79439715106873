import apiRequest from '../apis/api-request';
import { selectAuthToken } from '../redux/reducers/authReducers';
import { store } from '../redux/store';

const dropdownService = {

    userAddorEdit: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "GET",
            url: `/api/dropdown/useraddoredit`,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },

    assignedUserQuestion: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "GET",
            url: `/api/dropdown/assigneduserquestion`,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },

};

export default dropdownService;