import React from 'react'
import { Controller } from 'react-hook-form'

const Select = ({control, items, name, option}) => {
    const item = items?.find(element=>element.name=== name)

    if(!item){
        return  
    }

  return (
        <Controller
        name={item?.name}
        control={control}
        render={({ field }) => (
            <select
            className='h-[45px] w-full py-3 bg-white px-5 rounded-lg focus-within:outline-none border-2 border-[#dfdfdf] placeholder:text-[12px]'        
            placeholder={item?.placeholder? item?.placeholder :item?.label}
            name={item?.name}
            {...field}
            >
              <option value="">Select {item?.label}</option>
                {
                  option?.map((element,index)=>(
                    <option value={element?.id} key={index}>{element?.name}</option>
                  ))
                }
            </select>
        )}
        />  
    )
}

export default Select