import chapterService from "../services/chapterService";
import { useMutation } from "react-query";

const useChapterMutationList = (onData) => {
  return useMutation(chapterService.chapterList, {
    onSuccess: (data) => onData(data),
  });
};

const useChapterMutationCreate = (onData) => {
  return useMutation(chapterService.chapterCreate, {
    onSuccess: (data) => onData(data),
  });
};

const useChapterMutationUpdate = (onData) => {
  return useMutation(chapterService.chapterUpdate, {
    onSuccess: (data) => onData(data),
  });
};

const useChapterMutationDelete = (onData) => {
  return useMutation(chapterService.chapterDelete, {
    onSuccess: (data) => onData(data),
  })
}

const useChapterMutationAllList = (onData) => {
  return useMutation(chapterService.allChapterList, {
    onSuccess: (data) => onData(data),
  })
}

const useChapterAssignedMutationInspectionList = (onData) => {
  return useMutation(chapterService.assignChapterInspectionList, {
    onSuccess: (data) => onData(data),
  })
}


const chapterQueries = {
  useChapterMutationList,
  useChapterMutationCreate,
  useChapterMutationUpdate,
  useChapterMutationDelete,
  useChapterMutationAllList,
  useChapterAssignedMutationInspectionList
};

export default chapterQueries;
