import React from 'react'

const commonParams = (data) => {
    const newData = {
        size: data?.size? data?.size:20,
        page: data?.page? data?.page:0,
        search: data?.search? data?.search:"",
        status: data?.status? data?.status : "ACTIVE"
    }

    const params = `?size=${newData.size}&page=${newData.page}&search=${newData.search}&status=${newData.status}`

  return params
}

export default commonParams
