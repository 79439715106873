import commentServices from "../services/commentService";
import { useMutation } from "react-query";

const useCommentMutationCreate = (onData) => {
  return useMutation(commentServices.commentCreate, {
    onSuccess: (data) => onData(data),
  });
};

const useQuestionCommentMutationCreate = (onData) => {
  return useMutation(commentServices.questionCommentCreate, {
    onSuccess: (data) => onData(data),
  });
};

const useQuestionCommentMutationView = (onData) => {
  return useMutation(commentServices.questionCommentView, {
    onSuccess: (data) => onData(data),
  });
};

const commentQueries = {
  useCommentMutationCreate,
  useQuestionCommentMutationCreate,
  useQuestionCommentMutationView
};

export default commentQueries;
