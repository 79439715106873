import apiRequest from '../apis/api-request';
import { selectAuthToken } from '../redux/reducers/authReducers';
import { store } from '../redux/store';

const chapterService = {
    chapterList: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "GET",
            url: `/api/chapter`,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },

    chapterCreate: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "POST",
            url: `/api/chapter`,
            data: data,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },

    chapterUpdate: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "PUT",
            url: `/api/chapter/${data.id}`,
            data: data,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },

    chapterDelete: async (id) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "DELETE",
            url: `/api/chapter/${id}`,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },

    allChapterList: async (id) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "GET",
            url: `/api/chapter/alllist?vessel=${id}`,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },

    assignChapterInspectionList: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "GET",
            url: `/api/chapter/vessel/${data.vessel}/${data.inspection}?user_id=${data.user}`,
            data: data,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },

};

export default chapterService;