import React from 'react'

const AvatarBorder = () => {
  return (
    <div>
        <svg
        focusable="false"
        height="50px"
        viewBox="0 0 50 50"
        width="50px"
        xmlSpace="preserve"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        style={{
            opacity: 1,
        }}
        >
        <path
            d="M5.025 35.337A22.25 22.25 0 0 1 2.5 25c0 -3.588 0.85 -6.987 2.35 -10l-2.15 -1.3A24.875 24.875 0 0 0 0 25c0 4.138 1 8.037 2.788 11.475z"
            fill="#F6AD01"
        />
        <path
            d="M40.188 41.588C36.188 45.263 30.85 47.5 25 47.5c-8.688 0 -16.225 -4.938 -19.988 -12.163l-2.237 1.137C6.938 44.513 15.325 50 25 50c6.5 0 12.412 -2.475 16.85 -6.538z"
            fill="#249A41"
        />
        <path
            d="M41.863 43.463C46.863 38.9 50 32.313 50 25c0 -7.325 -3.15 -13.913 -8.175 -18.487l-1.713 1.825C44.65 12.463 47.5 18.4 47.5 25c0 6.563 -2.825 12.475 -7.313 16.587z"
            fill="#3174F1"
        />
        <path
            d="M25 2.5c5.813 0 11.113 2.212 15.113 5.838l1.713 -1.825A24.875 24.875 0 0 0 25 0C15.263 0 6.825 5.575 2.7 13.7L4.85 15C8.537 7.6 16.188 2.5 25 2.5"
            fill="#E92D18"
        />
        </svg>
    </div>
  )
}

export default AvatarBorder