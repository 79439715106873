import questionService from "../services/questionService";
import { useMutation } from "react-query";

const useQuestionCreateMutation = (onData) => {
  return useMutation(questionService.questionCreate, {
    onSuccess: (data) => onData(data),
  });
};

const useQuestionUpdateMutation = (onData) => {
  return useMutation(questionService.questionUpdate, {
    onSuccess: (data) => onData(data),
  });
};

const useQuestionListMutation = (onData) => {
  return useMutation(questionService.questionList, {
    onSuccess: (data) => onData(data),
  });
};

const useQuestionViewMutation = (onData) => {
  return useMutation(questionService.questionView, {
    onSuccess: (data) => onData(data),
  });
};

const useQuestionListingMutationInspection = (onData) => {
  return useMutation(questionService.questionListInspection, {
    onSuccess: (data) => onData(data),
  });
};

const useQuestionViewMutationInspection = (onData) => {
  return useMutation(questionService.questionViewInspection, {
    onSuccess: (data) => onData(data),
  });
};

const useQuestionChapterWiseList = (onData) => {
  return useMutation(questionService.questionChapterWiseList, {
    onSuccess: (data) => onData(data),
  });
};

const useQuestionAssign = (onData) => {
  return useMutation(questionService.questionAssign, {
    onSuccess: (data) => onData(data),
  });
};

const useUserAssignedQuestionList = (onData) => {
  return useMutation(questionService.userAssignedQuestionList, {
    onSuccess: (data) => onData(data),
  });
};

const useUserQuestionAssign = (onData) => {
  return useMutation(questionService.userQuestionAssign, {
    onSuccess: (data) => onData(data),
  });
};

const questionQueries = {
    useQuestionCreateMutation,
    useQuestionUpdateMutation,
    useQuestionListMutation,
    useQuestionViewMutation,
    useQuestionListingMutationInspection,
    useQuestionViewMutationInspection,
    useQuestionChapterWiseList,
    useQuestionAssign,
    useUserAssignedQuestionList,
    useUserQuestionAssign
};

export default questionQueries;
