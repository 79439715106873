import React, { useEffect, useState } from "react";
import { socket } from "../config/socket.config";
import { useDispatch, useSelector } from "react-redux";
import { setCompany } from "../redux/reducers/companyReducers";

const SocketProvider =()=> {

  const dispatch = useDispatch()

  useEffect(()=>{
    socket.on('company', (message) => {
      dispatch(setCompany(message))
    });    
    
    socket.on('updateActiveUsers', (message) => {
      console.log(message)
    });

  },[])

  return null

}

export default SocketProvider