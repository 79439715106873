import apiRequest from '../apis/api-request';
import { selectAuthToken } from '../redux/reducers/authReducers';
import { store } from '../redux/store';

const questionService = {
    questionCreate: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "POST",
            url: `/api/question`,
            data: data,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },    
    questionUpdate: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "PUT",
            url: `/api/question/update`,
            data: data,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },
    questionList: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "GET",
            url: `/api/question/list/${data.chapter_id}`,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },    
    questionView: async (id) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "GET",
            url: `/api/question/${id}`,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },
    questionListInspection: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "GET",
            url: `/api/question/alllist/${data.chapter}/${data.inspection}?user_id=${data.user}`,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },
    questionViewInspection: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "GET",
            url: `/api/question/view/${data.id}/${data.inspection}`,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },
    questionChapterWiseList: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "GET",
            url: `/api/question/chapterwise/list/${data.vessel}/${data.chapter}`,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },
    questionAssign: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "POST",
            url: `/api/question/assignquestion`,
            data: data,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },

    userAssignedQuestionList: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "POST",
            url: `/api/question/userassigned/questionlist`,
            data: data,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },

    userQuestionAssign: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "PUT",
            url: `/api/question/userquestion/assign`,
            data: data,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    }
};

export default questionService;