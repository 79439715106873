import { Progress } from '@nextui-org/react'
import React, { Fragment } from 'react'

const ProgressBar =({data})=>{
    return(
        <div className='w-[150px] flex flex-col gap-2'>
            <div className='flex justify-center font-semibold text-[12px]'>
                {data?.toFixed(0)}% Completed
            </div>
            <Progress
            classNames={{
                indicator: data > 80 ? '!bg-green-500' : data > 20 ? '!bg-blue-500' : '!bg-red-600'
            }}
            value={data} 
            />
        </div>
    )
}

export default ProgressBar