import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setPage } from '../redux/reducers/paginationReducers'
import { Pagination, PaginationItem } from '@mui/material'

const PaginationComponent = ({result}) => {

    const dispatch = useDispatch()
    const currentPage = useSelector((state)=> state.pagination.currentPage)

    const handlePage = (event, value) => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        dispatch(setPage(value-1))
    }

  return (
    <Fragment>
        {
            result?.data?.totalPages > 1 &&
            <div className='flex justify-end items-center py-3 px-8 gap-3'>
                <ul className="pagination pagination-sm justify-content-center table-pagination mt-4 mb-3 custom-pagination">
                    <Pagination
                        count={result?.data?.totalPages}
                        nextText="Next"
                        prevText="Previous"
                        sx={{
                            '& .MuiPagination-ul': {
                                rowGap: '10px'
                            }
                        }}
                        renderItem={
                            (item) => (
                                <div>
                                <PaginationItem
                                component="li"
                                {...item}
                                    sx={{
                                        '&.MuiPaginationItem-root': {
                                        backgroundColor: '#f0f0f0',
                                        borderRadius: '5px',
                                        minHeight: '40px',
                                        minWidth: '40px',
                                        color: '#1f1f1f',
                                        fontWeight: 500,
                                        fontSize: '13px',
                                        border: 'none',
                                        fontFamily: 'Montserrat'
                                        }
                                    }}
                                className={`${item.selected ? '!bg-primary !text-white' : '!bg-[#f0f0f0] !text-black'}`}
                                />
                                </div>
                            )
                        }
                        variant="outlined"
                        page={currentPage+1}
                        shape="rounded"                
                        onChange={handlePage}
                    />
                </ul>
            </div>
        }
    </Fragment>
  )
}

export default PaginationComponent
