import React, { Fragment, useEffect, useState } from 'react'
import vesselQueries from '../../queries/vesselQueries'
import { useDispatch, useSelector } from 'react-redux'
import { setHeaderText, setLoader } from '../../redux/reducers/optionsReducers'
import Loader from '../../components/Loader'


const OcimfScreen = () => {


    const [result, setResult] = useState([])
    const dispatch = useDispatch()
    const loader = useSelector((state)=> state.options.loader)

    useEffect(() => {
        dispatch(setLoader(true))
        dispatch(setHeaderText("Data From OCIMF"))
        vesselList.mutateAsync()
      }, []);


      function formatTimestamp(timestamp) {
        const date = new Date(timestamp);
    
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear());
    
        return `${day}-${month}-${year}`;
    }


    const vesselList = vesselQueries.useOcimfVesselMutationList(
        (res)=>{
          dispatch(setLoader(false))
          setResult(res)
        }
      )

      if(loader){
        return <Loader />
      }
    
    return (
       <Fragment>
      <div className=''>
        <div className='flex justify-end'>
        </div>
        <div className="table-wrpr">
            <div className="table-section">
                <table className="table prospect-table w-full">
                    <thead>
                        <tr>
                            <th>Vessel Id</th>
                            <th className='min-w-[120px]'>Vessel Name</th>
                            <th>Vessel IMO</th>
                            <th>Flag</th>
                            <th>Operator</th>
                           <th>Last Inspection Date</th>
                           <th>Last Inspection Member</th>
                        </tr>
                    </thead>
                    <tbody className="ui-sortable">
                        {
                            result?.data?.map((item, index) =>(
                            <tr className="ui-sortable-handle" key={index} >
                                <td>{item?.$?.vessel_id}</td>
                                <td>{item?.$?.vesselName}</td>
                                <td>{item?.$?.imo}</td>
                                <td>{item?.$?.flag}</td>
                                <td>{item?.$?.operatorText}</td>
                                <td>{formatTimestamp(item?.$?.lastInspectionDate)}</td>
                                <td>{item?.$?.lastInspectionMember}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>

      </div>
    </Fragment>
    )
}

export default OcimfScreen