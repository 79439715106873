import React, { Fragment, useState } from 'react'
import { MinusIcon, PlusIcon } from 'lucide-react'
import {AnimatePresence, motion} from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveTab, setHeaderText, setHeaderTextIntitial } from '../../redux/reducers/optionsReducers'
import { logout } from '../../redux/reducers/authReducers'
import { removeQuestionId } from '../../redux/reducers/questionReducers'
import MenuItems from '../../data/MenuItems'
import { resetPage } from '../../redux/reducers/paginationReducers'

const Sidebar = ({toggle}) => {

  const [expand, setExpand] = useState(null)
  
  const MenuItem = MenuItems()

  return (
    <Fragment>
        <div className={`${toggle?"w-[270px]":"w-[80px]"} h-full bg-primary fixed top-0 max-[768px]:hidden text-[14px] overflow-x-auto scroll-smooth scrollbar-hide`}>
          
          {
            toggle?
            <div className="flex flex-col pl-7 justify-center h-[70px]">
              <img src={require('../../assets/images/sire-logo.png')} width="165px" />
            </div>:
            <div className='flex items-center justify-center h-[70px]'>
            </div>
          }
          <div className='text-white'>
            {
              MenuItem?.map((item, index)=>(
                <Accordion item={item} expand={expand} setExpand={setExpand} toggle={toggle} index={index} key={index} />
              ))
            }
          </div>
        </div>

        <div className={`hidden ${toggle?"w-[270px] max-[768px]:block":"hidden"} h-full z-[998] bg-primary rounded-tr-[10px] rounded-br-2xl shadow-md fixed top-0 overflow-x-auto scroll-smooth scrollbar-hide z`}>
            <div className={`flex flex-col items-center ${toggle && "h-[70px]"}`}>
              <img className={`${!toggle&&"hidden"} mt-6`} src={require('../../assets/images/sire-logo.png')} width="165px" />
            </div>
            <div className='pt-5 text-white'>
            {
              MenuItem?.map((item, index)=>(
                <Accordion item={item} expand={expand} setExpand={setExpand} toggle={toggle} index={index} key={index} />
              ))
            }            
            </div>
        </div>
    </Fragment>
  )
}

export default Sidebar


const Accordion =({item, expand, setExpand, toggle, index})=>{

  const isOpen = index === expand
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const options = useSelector((state)=> state.options)

  const handleClick=()=>{
    dispatch(removeQuestionId())
    if(item.label === "Logout"){
      dispatch(setHeaderTextIntitial())
      dispatch(logout())
      dispatch(resetPage())
      return
    }

    if(item?.expand){
      setExpand(isOpen?'':index)
    }else{
      navigate(item?.url)
      dispatch(resetPage())
      dispatch(setActiveTab(item.url))
      dispatch(setHeaderText(item.label))
      setExpand('')
    }
  }

  const handleSubmenu=(subitem)=>{
    navigate(subitem.url)
    dispatch(setActiveTab(subitem.url))
    dispatch(resetPage())
    dispatch(setHeaderText(subitem.label))
    // setExpand('')
  }


  return(
    <div className='flex flex-col justify-between w-full items-center cursor-pointer'>
      <div className={`px-7  py-5 flex ${toggle?"justify-between":"justify-center"} w-full hover:bg-blue-700 ${item?.url === options?.activeTab && "bg-blue-500"}` } 
        onClick={handleClick}>
        <div className='flex gap-4'>
          <div>{item?.icon}</div>
          {
            toggle &&
            <div className='font-medium'>{item.label}</div>
          }
        </div>
        {
          item?.expand &&
          <div className={`${!toggle && "hidden"}`}>
            {
             isOpen?
             <MinusIcon size="20" />:
             <PlusIcon size="20" />
            }
          </div>
        }
      </div>
        {
          (isOpen && toggle) &&
          <AnimatePresence initial={false}>
              <motion.div
                initial={{ height: 0 }}
                className='w-full flex flex-col justify-start'
                animate={{ height: "auto" }}
                exit={{ height: 0 }}
                transition={{ type: "spring", duration: 0.4, bounce: 0 }}
              >
              {
                item?.more?.map((element, index2)=>(
                  <div className={`hover:bg-sky-700 ${element?.url === window.location.pathname && "bg-sky-500"} pl-12 py-4 flex items-center gap-2`} key={index2} onClick={()=>{handleSubmenu(element)}}>
                    {element?.icon} {element.label}
                  </div>
              ))}
            </motion.div>
          </AnimatePresence>
        }
    </div>
  )
}