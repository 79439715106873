import apiRequest from '../apis/api-request';
import { selectAuthToken } from '../redux/reducers/authReducers';
import { store } from '../redux/store';

const documentServices = {

    documentList: async (data) => {
        const token = selectAuthToken(store.getState());
        const vessel_id = data.vessel_id ? data.vessel_id : ''
        const question_id = data.question_id ? data.question_id : ''
        return apiRequest({
            method: "GET",
            url: `/api/document/list?vessel_id=${vessel_id}&question_id=${question_id}`,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },

    documentCreate: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "POST",
            url: `/api/document/create`,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "multipart/form-data"
            },
            data
        });
    },

    documentDelete: async (id) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "DELETE",
            url: `/api/document/${id}`,
            headers: {
                Authorization: "Bearer " + token
            }
        });
    },

};

export default documentServices;