import * as yup from 'yup'

export const generateValidationSchema = (inputFields) => {

    const validationSchema = inputFields?.reduce((accumulator, field) => {
    const { name, label, required, min, max } = field;
  
    let fieldValidator = yup.string().label(label).nullable()
  
    if (required) {
        fieldValidator = fieldValidator.required(`${label} is required`);
    }
    if (min !== undefined) {
        fieldValidator = fieldValidator.min(min, `Minimum length is ${min} characters`);
    }
    if (max !== undefined) {
        fieldValidator = fieldValidator.max(max, `Maximum length is ${max} characters`);
    }
    if (name === "email") {
        fieldValidator = fieldValidator.email("Enter valid email");
    }
    if(name==="mobile"){
        fieldValidator = fieldValidator.matches(
            /^(?:\+88|01)?(?:\d{11}|\d{13})$/,
            'Enter valid mobile number'
        );
    }
    if(name==="username"){
        fieldValidator = fieldValidator.matches(/^[a-zA-Z0-9]+$/, 'Username must only contain letters and numbers')
    }

    if (field.array) {
        fieldValidator = yup.array().of(fieldValidator);
    }

    if(name === "checklists"){
        fieldValidator = yup.array().of(yup.object().shape({
            checklist: yup.string().required("Checklist is required"),
            type: yup.string().required("Type is required"),
        }));

    }

    accumulator[name] = fieldValidator;
    return accumulator;

    }, {});
  
    return yup.object().shape(validationSchema);
};