import { Avatar, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger } from '@nextui-org/react'
import { LogOut } from 'lucide-react'
import React, { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../redux/reducers/authReducers' 
import { RouteConstant } from '../../routes/RouteConstant'
import { useNavigate } from 'react-router-dom'
import { setHeaderText, setHeaderTextIntitial } from '../../redux/reducers/optionsReducers'
import { IconUser } from '@tabler/icons-react'
import AvatarBorder from '../../components/AvatarBorder'

const Header = ({toggle}) => {

  const user = useSelector((state) => state.auth.user);
  const options = useSelector((state)=> state.options)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const logoutUser =()=>{
    navigate(RouteConstant.ROOT)
    dispatch(setHeaderTextIntitial())
    dispatch(logout());
  }

  const menuItems = [
    // {
    //   label:"Company Profile",
    //   icon: <IconUser size="15"/>,
    //   color: "primary",
    //   onClick: ()=> navigate(RouteConstant.PROFILE)
    // },
    {
      label:"Logout",
      icon: <LogOut size="15" />,
      color: "danger",
      onClick: logoutUser
    }
  ]


  return (
    <Fragment>
      <div className="w-full h-[70px] flex bg-white top-0 max-[768px]:bg-white items-center px-6 text-[14px]">
        <div className='flex justify-between w-full items-center'>
          <div className='max-[768px]:hidden font-semibold text-[16px]'>
            {options.headerText}
          </div>
          <div className={`hidden max-[768px]:flex max-[768px]:ml-10`}>
            <img src={require('../../assets/images/sire-logo-2.png')} width="190px" />
          </div>

          
          


            <Dropdown placement="bottom-start">
              <DropdownTrigger>
                <div className={`${toggle&&"max-[768px]:block"} flex  items-center gap-4 cursor-pointer`}>
                  <div className='max-[768px]:hidden'>Welcome {user?.username}</div>
                  <div className='relative w-[50px] h-[50px]'>
                    <div className='absolute right-0 top-0'>
                      <AvatarBorder />
                    </div>
                  <img className='w-[50px] h-[50px] rounded-full object-cover p-[4px]' src='https://media.istockphoto.com/id/1389898125/photo/young-woman-chewing-gum-cute-iconic-character-3d-rendering.jpg?s=612x612&w=0&k=20&c=qriGPWcu_8VDPI6VGmyD0oHR80lqMnk8_AtwEPm8Guo=' size='sm' />
                  </div>
                </div>
              </DropdownTrigger>
              <DropdownMenu  aria-label="User Actions" variant="flat">
                <DropdownItem key="profile" className="px-10 py-4 gap-2">
                  <p className="font-bold">Signed in as {user?.username}</p>
                </DropdownItem>
                {menuItems?.map((item, index)=>(
                  <DropdownItem onClick={item.onClick} key={index} startContent={item.icon} color={item.color}>
                    {item.label}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>

        </div>
      </div>
    </Fragment>
  )
}

export default Header