import React, { Fragment, useEffect, useState } from 'react'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Typography, createTheme } from '@mui/material'
import DrawerComponent from '../../components/DrawerComponent'
import authQueries from '../../queries/authQueries'
import { useApi } from '../../components/ApiProvider'
import dropdownQueries from '../../queries/dropdownQueries'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import PaginationComponent from '../../components/PaginationComponent'
import LoaderSmall from '../../components/LoaderSmall'


const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 13
  },
});

const UsersList = () => {

    const [result, setResult] = useState([])
    const [roles, setRoles] = useState([])
    const [search, setSearch] = useState("")

    const currentPage = useSelector((state)=> state.pagination.currentPage)

    const inputFields = [
        {
            name: 'username',
            type: 'text',
            label: 'Username',
            required: true
        },
        {
            name: 'fname',
            type: 'text',
            label: 'First Name',
            required: true
        },
        {
            name: 'lname',
            type: 'text',
            label: 'Last Name',
            required: true
        },
        {
            name: 'email',
            type: 'email',
            label: 'Email',
            required: true
        },
        {
            name: 'phone',
            type: 'text',
            label: 'Mobile Number',
            required: true
        },
        {
            name: 'role_id',
            type: 'select',
            label: 'Role',
            options: roles,
            required: true
        }
    ]

    const { apiData, setApiDataAndNotify } = useApi()
    
    const userAddorEdit = dropdownQueries.useUserAddOrEditMutation(
        (res)=>{
            setRoles(res?.roles)
        }
    )

    useEffect(() => {
        userAddorEdit.mutateAsync({})
    }, []);

    const userList = authQueries.useUserListMutation(
        (res)=>{
            setResult(res)
        }
    )

    useEffect(() => {
        userList.mutateAsync({page: currentPage, size: 10, search: search})
    }, [apiData, currentPage, search]);

    const createUser = authQueries.useCreateUserMutation(
        (res) => {
            setApiDataAndNotify(res)
            toast.success('User created successfully')
        }
    );

    const updateUser = authQueries.useUpdateUserMutation(
        (res) => {
            setApiDataAndNotify(res)
            toast.success('User updated successfully')
        }
    );

  return (
    <div className='card-shadow m-10 max-[768px]:m-2 bg-white rounded-[10px]'>

        <div className='flex justify-between max-[995px]:flex-col items-center p-3 gap-y-3'>
            {
                result?.data?.totalItems > 0 ?
                <div className='text-gray-600 text-[13px] font-medium'>{`Showing ${result?.data?.currentPage * 50 + 1} to ${result?.data?.currentPage * 50 + result?.data?.items?.length} of ${result?.data?.totalItems} entries`}</div>:
                <div></div>
            }
            <div className='flex max-[561px]:flex-col max-[561px]:w-full justify-end items-center gap-3'>
                <input onChange={(e)=>setSearch(e.target.value)} type="text" className='w-[300px] max-[561px]:w-full h-[40px] border rounded-[7px] px-3 mt-[1px]' placeholder='Search' />
                {/* <DrawerComponent entityForm={createUser} inputFields={inputFields} btnName={'Add User'} header={'Add User'} /> */}
            </div>
        </div>

        {
            userList?.isLoading ?
            <div className="w-full flex items-center justify-center py-40">
                <LoaderSmall />
            </div>:
            <Fragment>
                <ThemeProvider theme={theme}>
                    <TableContainer 
                    component={Paper}
                    sx={{
                        boxShadow: 'none',
                        width: '100%',
                        borderRadius: 0,
                        borderTop: '1px solid #e6e6e8',
                        borderBottom: '1px solid #e6e6e8',
                        fontWeight: 500, fontSize: "13px"
                    }}
                    >
                        <Table
                        aria-label="simple table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center' width={30}>
                                        <b>#</b>
                                    </TableCell>
                                    <TableCell><b>Username</b></TableCell>
                                    <TableCell><b>Full Name</b></TableCell>
                                    <TableCell><b>Email</b></TableCell>
                                    <TableCell><b>Mobile Number</b></TableCell>
                                    <TableCell><b>Role</b></TableCell>
                                    <TableCell><b>Vessel</b></TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {result?.data?.items?.map((row, index) => (
                                    <TableRow
                                    key={row.id}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        fontFamily: 'Montserrat, sans-serif'
                                    }}
                                    >
                                        <TableCell align='center' width={30}>
                                            <Typography sx={{fontWeight: 500, fontSize: "13px"}}>
                                                {(index + 1) + (result?.data?.currentPage * 10)}
                                            </Typography>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Typography sx={{fontWeight: 500, fontSize: "13px"}}>
                                                {row.username}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{fontWeight: 500, fontSize: "13px"}}>
                                                {row.fname} {row.lname}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{fontWeight: 500, fontSize: "13px"}}>
                                                {row.email}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{fontWeight: 500, fontSize: "13px"}}>
                                                {row.phone}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{fontWeight: 500, fontSize: "13px"}}>
                                                {row.role?.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{fontWeight: 500, fontSize: "13px"}}>
                                                {row.vessel?.name}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <DrawerComponent data={row} edit={true} entityForm={updateUser} inputFields={inputFields} btnName={'Update User'} header={'Update User'} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ThemeProvider>
                <PaginationComponent result={result} />
            </Fragment>
        }


    </div>
  )
}

export default UsersList