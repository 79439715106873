import React from 'react'
import '../assets/css/loadersmall.css'

const LoaderSmall = () => {
    return (
        <div className="loading">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}

export default LoaderSmall