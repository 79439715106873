import React, { useEffect, useState } from "react";

function getRemoteFileAsDataURI(url) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.onload = function(e) {
        if (this.status === 200) {
          const reader = new FileReader();
          reader.readAsDataURL(this.response);
          reader.onloadend = () => resolve(reader.result);
        } else {
          reject(new Error(`Error fetching image: ${this.statusText}`));
        }
      };
  
      xhr.onerror = reject;
      xhr.send();
    });
  }

function Base64Images({imageUrl}) {

    const [base64Data, setBase64Data] = useState(null);
    const [error, setError] = useState(null);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const dataURI = await getRemoteFileAsDataURI(process.env.REACT_APP_BASE_URL+`/api/fileupload/${imageUrl}`); // Replace with your desired image URL
            const image = new Image();
            image.src = dataURI;
            image.onload = () => {
              const aspectRatio = image.width / image.height;
              const newWidth = 70
              const newHeight = newWidth * aspectRatio;
              setWidth(newWidth);
              setHeight(newHeight);
            }
            setBase64Data(dataURI);
          } catch (err) {
            setError(err.message);
          }
        };
    
        fetchData();
    }, []);

    return (
      <div>
          <a href={process.env.REACT_APP_BASE_URL+`/api/fileupload/${imageUrl}`} target="_blank">
            <img className="rounded-lg" id="image0_657_192" width={width} height={height} src={process.env.REACT_APP_BASE_URL+`/api/fileupload/${imageUrl}`} />
          </a>
      </div> 
    )
}

export default Base64Images;
