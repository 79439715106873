import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Eye, EyeOff, KeyRound, User2 } from 'lucide-react'
import { yupResolver } from '@hookform/resolvers/yup';
import { generateValidationSchema } from '../../utils/validationSchema';
import {ValidationError} from '../../components/validationError';
import authQueries from '../../queries/authQueries';
import toast from 'react-hot-toast';
import { Spinner } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';
import {RouteConstant} from '../../routes/RouteConstant'
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/reducers/authReducers';
import { setActiveTab, setHeaderText } from '../../redux/reducers/optionsReducers';

const LoginScreen = () => {
  
  const [showPass, setShowPass] = useState(false)
  
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const inputFields = [
    {
      name: 'username',
      label: "Username",
      required: true,
      min:5,
      max:32
    },
    {
      name: "password",
      label: "Password",
      required: true,
      type: "password"
    }
  ]

  const {handleSubmit, register, formState: {errors}} = useForm({
      resolver: yupResolver(generateValidationSchema(inputFields)),
  })


  const loginMutation = authQueries.useLoginMutation(
    (res)=>{
      if(res?.message==="Success"){
        if(res?.data?.role?.role === "SADMIN"){
          setData("Vessel", RouteConstant.VESSEL)
        }else if(res?.data?.role?.role === "CAPT"){
          setData("Vessel Details - "+res?.data?.vessel?.name, RouteConstant.VESSELDETAILS)
        }else{
          setData("Vessel Details - "+res?.data?.vessel?.name, RouteConstant.VESSELDETAILS)
        }
        dispatch(login(res?.data));
        return
      }else{
        toast.error(res?.message)
      }
    }
  )

  const onSubmit=(data)=>{
    loginMutation.mutateAsync(data)
  }


  useEffect(() => {
    if(isAuthenticated){
      if(user?.role?.role === "SADMIN"){
        setData("Vessel", RouteConstant.VESSEL)
      }else if(user?.role?.role === "CAPT"){
        setData("Vessel Details - "+user?.vessel?.name, RouteConstant.VESSELDETAILS)
      }else{
        setData("Vessel Details - "+user?.vessel?.name, RouteConstant.VESSELDETAILS) 
      }
    }
  }, []);

  const setData = (header, url) => {
    navigate(url)
    dispatch(setActiveTab(url))
    dispatch(setHeaderText(header))
  }

  return (
    <div className='flex flex-col justify-center items-center min-h-[100vh] loginBg bg-cover bg-no-repeat custom-bg-center'>
      <div className='flex flex-col gap-11 max-[530px]:gap-6 items-center w-[100%]'>
        <div className=''>
          <img className="max-w-[370px] max-[425px]:max-w-[250px]" src={require('../../assets/images/sire-logo-2.png')} />
        </div>
        <div className='text-white text-center'>
            SIGN IN
        </div>
          <div className='flex flex-col gap-8 text-[14px] max-[530px]:gap-4 w-[530px] max-[817px]:w-[450px] max-[625px]:w-[400px] max-[550px]:w-[350px] max-[425px]:w-[90%] max-[500px]:text-[13px]'>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='flex flex-col gap-2'>
                  <div>Username</div>
                  <div className='relative'>
                      <User2 className='text-primary absolute left-3 top-4'/>
                      <input 
                      {...register('username')}
                      name='username'
                      placeholder="Enter Username" 
                      className="w-full min-h-[55px] rounded-[11px] bg-[#ffffffbd] pl-12 focus-within:outline-none focus-within:bg-white"/>
                  </div>
                  <ValidationError error={errors?.username} />
              </div>
              <div className='flex flex-col gap-2'>
                  <div>Password</div>
                  <div className='relative'>
                    <KeyRound className='text-primary absolute left-3 top-4'/>
                    <input
                    {...register('password')}
                    name='password'
                    type={showPass?"text":"password"}
                    placeholder="Enter Password" 
                    className="w-full min-h-[55px] rounded-[11px] bg-[#ffffffbd] pl-12 focus-within:outline-none focus-within:bg-white"/>
                    {
                      showPass?
                      <EyeOff onClick={()=>{setShowPass(!showPass)}} className='text-primary absolute right-5 bottom-4 cursor-pointer'/>:
                      <Eye onClick={()=>{setShowPass(!showPass)}} className='text-primary absolute right-5 bottom-4 cursor-pointer'/>
                    }
                  </div>
                    <ValidationError error={errors?.password} />
                  <div className="flex justify-between mt-1 mb-2">
                      <a href="#">Remember Me</a>
                      <a href="#">Forgot Password?</a>
                  </div>
              </div>
              <div>
                  <button disabled={loginMutation.isLoading} type="submit" className="flex items-center justify-center w-full bg-btnlogin text-white rounded-[11px] min-h-[55px]">
                      {
                        loginMutation.isLoading ?
                        <Spinner color='white' size='sm' />:
                        <Fragment>
                        LOGIN
                        <img src={require('../../assets/images/login-icon.png')} width="48px"/>
                        </Fragment>
                      }
                  </button>
              </div>
            </form>
          </div>
      </div>
    </div>
  )
}

export default LoginScreen