import React, { useEffect } from 'react'
import { QuestionFormInput } from '../../../data/QuestionFormInput'
import { useFieldArray, useForm } from 'react-hook-form'
import TextArea from '../../../components/TextArea'
import Input from '../../../components/Input'
import TextEditor from '../../../components/TextEditor'
import RadioGroups from '../../../components/RadioGroup'
import { Check, ChevronLeft, PlusIcon, X } from 'lucide-react'
import questionQueries from '../../../queries/questionQueries'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { RouteConstant } from '../../../routes/RouteConstant'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { generateValidationSchema } from '../../../utils/validationSchema'
import { ValidationErrorMaster } from '../../../components/validationError'
import { setLoader } from '../../../redux/reducers/optionsReducers'
import Loader from '../../../components/Loader'
import Select from '../../../components/Select'

const radioData = [
  {label:"Binary", value: "BINARY"},
  {label:"Graded", value: "GRADED"},
]

const QuestionForm = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch()

  const chapter = useSelector((state)=> state.master.chapter)
  const loader = useSelector((state)=> state.options.loader)

  const {control, register, handleSubmit, setValue, reset, formState:{errors}} = useForm({
    defaultValues: {checklists: [{checklist: ""}]},
    resolver: yupResolver(generateValidationSchema(QuestionFormInput))
  })

  const qid = useSelector((state) => state.question.qid);

  const viewQuestion = questionQueries.useQuestionViewMutation(
    (res)=>{
        dispatch(setLoader(false))
        reset(res?.data)
    }
  )

  useEffect(() => {
    if(qid){
      dispatch(setLoader(true))
      viewQuestion.mutateAsync(qid)
    }
  }, []);

  const questionCreate = questionQueries.useQuestionCreateMutation(
    (res)=>{
      toast.success("Successfully created question")
      navigate(RouteConstant.QUESTIONLIST)
    }
  )

  const questionUpdate = questionQueries.useQuestionUpdateMutation(
    (res)=>{
      toast.success("Successfully updated question")
      navigate(RouteConstant.QUESTIONLIST)
    }
  )

  const onSubmit=(data)=>{
    const newValue = {
      ...data,
      chapter_id: chapter?.id
    }
    questionCreate.mutateAsync(newValue)
  }

  const onUpdate=(data)=>{
    questionUpdate.mutateAsync(data)
  }

  const { fields, append, remove } = useFieldArray({
    control,
    name: "checklists",
  });

  const tagOptions = [
    {name:"Core", id: "CORE"},
    {name:"Rotational", id: "ROTATIONAL"},
    {name:"Campaign", id: "CAMPAIGN"},
    {name:"Conditional", id: "CONDITIONAL"},
  ]

  const sectionOption =()=>{
    let temp = []
    for(let i=1; i<=20; i++){
      temp.push({id: i, name: i})
    }
    temp.push({id: 99, name: 99})
    return temp
  }

  const subSectionOption =()=>{
    let temp = []
    for(let i=1; i<=95; i++){
      temp.push({id: i, name: i})
    }
    return temp
  }


  if(loader){
    return <Loader />
  }

  console.log(errors)

  return (
    <div>

      <div className='flex justify-between max-md:flex-col-reverse max-md:gap-2 items-center mb-10'>
          <div className='flex gap-6 items-center'>
              <ChevronLeft size="16" className='cursor-pointer' onClick={()=>{navigate(-1)}} />
              <div className='font-medium text-[16px]'>
                  {chapter?.chapter_no} {chapter?.name}
              </div>
          </div>
      </div>

      <form onSubmit={handleSubmit(qid?onUpdate:onSubmit)}>
        <div className='grid grid-cols-12 gap-8 text-[14px]'>
            <div className="col-span-12 flex flex-col gap-2">
              <div>Questions</div>
              <TextArea control={control} name="question" items={QuestionFormInput} />
              <ValidationErrorMaster error={errors?.question} />
            </div>
            <div className="col-span-6  flex flex-col gap-2">
              <div>Short Question</div>
              <Input control={control} name="short_question" items={QuestionFormInput} />
              <ValidationErrorMaster error={errors?.short_question} />
            </div>
            <div className="col-span-6 flex flex-col gap-2">
              <div>ROVIQ Sequence</div>
              <Input control={control} name="roviq" items={QuestionFormInput} />
              <ValidationErrorMaster error={errors?.roviq} />
            </div>
            <div className="col-span-6 flex flex-col gap-2">
              <div>Tag</div>
              <Select control={control} name="tag" option={tagOptions} items={QuestionFormInput} />
              <ValidationErrorMaster error={errors?.tag} />
            </div>
            <div className="col-span-3 max-[643px]:col-span-6 flex flex-col gap-2">
              <div>Section</div>
              <Select control={control} name="section" option={sectionOption()} items={QuestionFormInput} />
              <ValidationErrorMaster error={errors?.section} />
            </div>
            <div className="col-span-3 max-[643px]:col-span-6 flex flex-col gap-2">
              <div>Sub Section</div>
              <Select control={control} name="sub_section" option={subSectionOption()} items={QuestionFormInput} />
              <ValidationErrorMaster error={errors?.sub_section} />
            </div>
            <div className="col-span-6 flex flex-col gap-2">
              <div>Publications</div>
              <TextArea control={control} name="publications" items={QuestionFormInput} />
              <ValidationErrorMaster error={errors?.publications} />
            </div>
            <div className="col-span-12 flex flex-col gap-2  mb-12">
              <div>Objectives</div>
              <TextEditor control={control} setValue={setValue} name="objectives" />
            </div>
            <div className="col-span-12 flex flex-col gap-2  mb-12">
              <div>Industry Guidance</div>
              <TextEditor control={control} setValue={setValue} name="industry_guidance" />
            </div>
            <div className="col-span-12 flex flex-col gap-2  mb-12">
              <div>Inspection Guidance</div>
              <TextEditor control={control} setValue={setValue} name="inspection_guidance" />
            </div>
            <div className="col-span-12 flex flex-col gap-2  mb-12">
              <div>Suggested Inspection Actions</div>
              <TextEditor control={control} setValue={setValue} name="suggested_inspector_actions" />
            </div>
            <div className="col-span-12 flex flex-col gap-2  mb-12">
              <div>Expected Evidence</div>
              <TextEditor control={control} setValue={setValue} name="expected_evidence" />
            </div>
            <div className="col-span-12 flex flex-col gap-2  mb-12">
              <div>Potential Grounds for a Negative Observation</div>
              <TextEditor control={control} setValue={setValue} name="negative_observation" />
            </div>
            <div className="col-span-4 flex flex-col gap-2">
              <div>Process</div>
              <RadioGroups data={radioData} name="process" control={control} />
            </div>
            <div className="col-span-4 flex flex-col gap-2">
              <div>Human</div>
              <RadioGroups data={radioData} name="human" control={control} />
            </div>
            <div className="col-span-4 flex flex-col gap-2">
              <div>Hardware</div>
              <RadioGroups data={radioData} name="hardware" control={control} />
            </div>
            <div className="col-span-12 flex flex-col gap-2">
              <div>Add Checklist</div>
                  {fields.map((field, index) => (
                    <div className='flex gap-2' key={index}>
                        <div className='w-[70%] '>
                          <input
                            key={field.id}
                            className='h-[45px] w-full py-3 px-5 rounded-lg focus-within:outline-none border-2 border-[#dfdfdf] placeholder:text-[12px]'
                            {...register(`checklists.${index}.checklist`)} 
                          />
                          <ValidationErrorMaster error={errors?.checklists?.[index]?.checklist} />
                        </div>
                        <div  className='w-[15%]'>
                          <select
                            className='h-[45px] w-full py-3 px-5 rounded-lg focus-within:outline-none border-2 border-[#dfdfdf] bg-white placeholder:text-[12px]'
                            {...register(`checklists.${index}.type`)} 
                          >
                            <option value="">Select Type</option>
                            <option>PROCESS</option>
                            <option>HUMAN</option>
                            <option>HARDWARE</option>
                          </select>
                          <ValidationErrorMaster error={errors?.checklists?.[index]?.type} />
                        </div>
                        <button
                        className='w-[60px] h-[45px] bg-green-500 flex justify-center items-center rounded-lg'
                        type="button"
                        onClick={() => append({ checklist:"" })}
                        >
                        <PlusIcon color='white' />
                        </button>
                        <button
                        className='w-[60px] h-[45px] bg-red-500 flex justify-center items-center rounded-lg'
                        type="button"
                        disabled={index===0?true:false}
                        onClick={() => remove(index)}
                        >
                        <X color='white' />
                        </button>
                    </div>
                  ))}            
            </div>
            <div className="col-span-12 flex gap-2 justify-end mt-10">
              <button className='bg-primary text-white px-8 py-2 rounded-md flex gap-2 items-center' type='reset'>
                Cancel
                <X size="20" className='text-red-500' />
              </button>
              <button className='bg-primary text-white px-8 py-2 rounded-md flex gap-2 items-center' type='submit'>
                Submit
                <Check size="20" className='text-green-500' />
              </button>
            </div>
        </div>
      </form>


    </div>
  )
}

export default QuestionForm
