import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: '',
  header: '',
  chapter: {
    id: '',
    chapter_no  : '',
    name: ''
  },
  inspection: '',
  question: {
    id: '',
    question: ''
  },
  vessel: {
    id: '',
    name: '',
    imo: '',
    built: '',
    type: '',
    dwt: '',
    owner: '',
  },
  user: {},
  isUserWise: false
};

const masterSlice = createSlice({
  name: 'master',
  initialState,
  reducers: {
    setMaster: (state, action) => {
      state.id = action.payload.id;
      state.header = action.payload.header
    },
    removeMaster: (state) => {
        state.id = "";
        state.header = "";
    },
    setChapter: (state, action) => {
      state.chapter = {
        id: action.payload.id,
        chapter_no: action.payload.chapter_no,
        name: action.payload.name
      }
    },
    setVessel: (state, action) => {
      state.vessel = {
        id: action.payload.id,
        name: action.payload.name,
        imo: action.payload.imo,
        built: action.payload.built,
        type: action.payload.type,
        dwt: action.payload.dwt,
        owner: action.payload.owner,
      }
    },
    removeChapter: (state) => {
      state.chapter = initialState.chapter
    },
    setInspection: (state, action) => {
      state.inspection = action.payload
    },
    setQuestion: (state, action) => {
      state.question = {
        id: action.payload.id,
        question: action.payload.question
      }
    },
    setUser: (state, action) => {
      state.user = action.payload
    },
    setIsUserWise: (state, action) => {
      state.isUserWise = action.payload
    }
  },
});

export const { setMaster, removeMaster, setChapter, removeChapter, setInspection, setQuestion, setVessel, setUser, setIsUserWise } = masterSlice.actions;
export default masterSlice.reducer;