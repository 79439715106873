import { Icon12Hours, IconBrandAppgallery, IconCode, IconDeviceAudioTape, IconPdf, IconVideo } from "@tabler/icons-react";
import { FileIcon } from "lucide-react";

export const fileTypes = {
    Images: [
        'image/jpeg',
        'image/png',
        'image/gif',
        'image/webp',
        'image/svg+xml',
        'image/bmp',
        'image/tiff'
    ],
    PDF: ['application/pdf'],
    WordDocuments: [
        'application/msword', // Microsoft Word 97-2003 (DOC)
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' // Microsoft Word (DOCX)
    ],
    ExcelDocuments: [
        'application/vnd.ms-excel', // Microsoft Excel 97-2003 (XLS)
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // Microsoft Excel (XLSX),
        'application/vnd.oasis.opendocument.spreadsheet'
    ],
    Presentations: [
        'application/vnd.oasis.opendocument.presentation', // LibreOffice Impress (ODP)
        'application/vnd.ms-powerpoint', // Microsoft PowerPoint 97-2003 (PPT)
        'application/vnd.openxmlformats-officedocument.presentationml.presentation' // Microsoft PowerPoint (PPTX)
    ],
    TextDocuments: [
        'text/plain', // Plain Text
        'application/rtf' // Rich Text Format (RTF)
    ],
    eBooks: [
        'application/epub+zip' // EPUB eBook
    ],
    LaTeX: [
        'application/x-tex' // LaTeX Document
    ],
    ProjectFiles: [
        'application/vnd.ms-project' // Microsoft Project (MPP)
    ],
    PublisherFiles: [
        'application/x-mspublisher' // Microsoft Publisher (PUB)
    ],
    Videos: [
        'video/mp4',
        'video/x-msvideo',
        'video/x-matroska',
        'video/webm',
        'video/quicktime',
        'video/ogg'
    ],
    Audios: [
        'audio/mpeg',
        'audio/ogg',
        'audio/wav',
        'audio/aac',
        'audio/mp4',
        'audio/x-m4a'
    ],
    Archives: [
        'application/zip',
        'application/x-rar-compressed',
        'application/x-tar',
        'application/x-7z-compressed',
        'application/x-gzip'
    ],
    JS: [
        'application/javascript',
        'application/x-javascript',
        'text/javascript',
        'text/x-javascript'
    ],
    HTML: [
        'text/html',
        'text/x-html'
    ],
    CSS: [
        'text/css',
        'text/x-css'
    ],
    Codes: [
        'application/json',
        'application/xml',
        'text/x-python',
        'text/x-java-source'
    ],
    Executables: [
        'application/x-msdownload',
        'application/x-sh',
        'application/x-java-archive',
        'application/x-msi'
    ],
    Others: [
        'application/x-csh',
        'application/x-bzip2',
        'application/x-rar',
        'application/x-tar'
    ]
};


export const fileImages = {
    'Images': <IconBrandAppgallery size="20" />,
    'Videos': <img src="https://www.iconpacks.net/icons/1/free-video-icon-818-thumb.png" className="w-[60px] h-[60px]" />,
    'PDF': <img src={require('../assets/images/pdficon.png')} className="w-[70px] h-[90px]" />,
    'WordDocuments': <img src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/microsoft-word-icon.png" className="w-[100px] h-[100px]" />,
    'ExcelDocuments': <img src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/google-sheets-icon.png" className="w-[100px] h-[100px]" />,
    'Presentations':  <img src="https://images.vexels.com/media/users/3/276661/isolated/preview/614fa2f6000e812cb013b82d5ed0eb21-blue-folder-squared.png" className="w-[100px] h-[100px]" />,
    'TextDocuments':  <img src="https://images.vexels.com/media/users/3/276661/isolated/preview/614fa2f6000e812cb013b82d5ed0eb21-blue-folder-squared.png" className="w-[100px] h-[100px]" />,
    'eBooks':  <img src="https://images.vexels.com/media/users/3/276661/isolated/preview/614fa2f6000e812cb013b82d5ed0eb21-blue-folder-squared.png" className="w-[100px] h-[100px]" />,
    'LaTeX':  <img src="https://images.vexels.com/media/users/3/276661/isolated/preview/614fa2f6000e812cb013b82d5ed0eb21-blue-folder-squared.png" className="w-[100px] h-[100px]" />,
    'ProjectFiles':  <img src="https://images.vexels.com/media/users/3/276661/isolated/preview/614fa2f6000e812cb013b82d5ed0eb21-blue-folder-squared.png" className="w-[100px] h-[100px]" />,
    'PublisherFiles':  <img src="https://images.vexels.com/media/users/3/276661/isolated/preview/614fa2f6000e812cb013b82d5ed0eb21-blue-folder-squared.png" className="w-[100px] h-[100px]" />,
    'Audios':  <img src="https://images.vexels.com/media/users/3/276661/isolated/preview/614fa2f6000e812cb013b82d5ed0eb21-blue-folder-squared.png" className="w-[100px] h-[100px]" />,
    'Archives':  <img src="https://images.vexels.com/media/users/3/276661/isolated/preview/614fa2f6000e812cb013b82d5ed0eb21-blue-folder-squared.png" className="w-[100px] h-[100px]" />,
    'JS':  <img src="https://cdn-icons-png.flaticon.com/512/5968/5968292.png" className="w-[70px] h-[70px]" />,
    'HTML':  <img src="https://static-00.iconduck.com/assets.00/html-icon-1451x2048-69sehqrp.png" className="w-[60px] h-[80px]" />,
    'Codes':  <img src="https://cdn-icons-png.flaticon.com/512/5968/5968292.png" className="w-[100px] h-[100px]" />,
    'Executables': <img src="https://images.vexels.com/media/users/3/276661/isolated/preview/614fa2f6000e812cb013b82d5ed0eb21-blue-folder-squared.png" className="w-[100px] h-[100px]" />,
    'Others': <img src="https://images.vexels.com/media/users/3/276661/isolated/preview/614fa2f6000e812cb013b82d5ed0eb21-blue-folder-squared.png" className="w-[100px] h-[100px]" />
}