import apiRequest from '../apis/api-request';
import { selectAuthToken } from '../redux/reducers/authReducers';
import { store } from '../redux/store';

const fileuploadService = {
    multipleFileUpload: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "POST",
            url: `/api/fileupload/upload`,
            data: data,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type":  "multipart/form-data"
            },
        });
    }
};

export default fileuploadService;