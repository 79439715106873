import documentService from "../services/documentService";
import { useMutation } from "react-query";

const useDocumentMutationList = (onData) => {
  return useMutation(documentService.documentList, {
    onSuccess: (data) => onData(data),
  });
};

const useDocumentMutationCreate = (onData) => {
  return useMutation(documentService.documentCreate, {
    onSuccess: (data) => onData(data),
  });
};

const useDocumentMutationDelete = (onData) => {
  return useMutation(documentService.documentDelete, {
    onSuccess: (data) => onData(data),
  });
};

const documentQueries = {
    useDocumentMutationList,
    useDocumentMutationCreate,
    useDocumentMutationDelete
};

export default documentQueries;
