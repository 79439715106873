import vesselService from "../services/vesselService";
import { useMutation } from "react-query";

const useVesselMutationList = (onData) => {
  return useMutation(vesselService.vesselList, {
    onSuccess: (data) => onData(data),
  });
};

const useVesselMutationCreate = (onData) => {
  return useMutation(vesselService.vesselCreate, {
    onSuccess: (data) => onData(data),
  });
};

const useVesselMutationUpdate = (onData) => {
  return useMutation(vesselService.vesselUpdate, {
    onSuccess: (data) => onData(data),
  });
};

const useVesselMutationDelete = (onData) => {
  return useMutation(vesselService.vesselDelete, {
    onSuccess: (data) => onData(data),
  });
};


const useVesselMutationView = (onData) => {
  return useMutation(vesselService.vesselView, {
    onSuccess: (data) => onData(data),
  });
};

const useOcimfVesselMutationList = (onData) => {
  return useMutation(vesselService.ocimfvesselList, {
    onSuccess: (data) => onData(data),
  });
};

const vesselQueries = {
  useVesselMutationList,
  useVesselMutationCreate,
  useVesselMutationUpdate,
  useVesselMutationDelete,
  useVesselMutationView,
  useOcimfVesselMutationList
};

export default vesselQueries;
