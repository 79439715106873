import React, { Fragment } from 'react'
import ReactApexChart from 'react-apexcharts';

const DonutChart = ({data}) => {
  
    const options = {
      chart: {
        type: "donut",
      },
      series: data,
      labels: ["success", 'pending'],
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#54B435", "#0766AD"]
    };
  

  return (
    <div className='w-[160px]'>
        <ReactApexChart
        options={options}
        series={options.series}
        type="donut"
        height={160}
      />
    </div>
  )
}

export default DonutChart
