import React, { Fragment, useEffect, useState } from 'react'
import chapterQueries from '../../queries/chapterQueries'
import { useDispatch, useSelector } from 'react-redux'
import BreadCrumbs from '../../components/BreadCrumbs'
import { RouteConstant } from '../../routes/RouteConstant'
import { setChapter } from '../../redux/reducers/masterReducers'
import { useNavigate } from 'react-router-dom'
import { setHeaderText, setLoader } from '../../redux/reducers/optionsReducers'
import DonutChart from '../../components/DonutChart'
import Loader from '../../components/Loader'

const breadcrumb = [
  {
    label: "Vessel",
    path: RouteConstant.VESSEL,
    roles: ["SADMIN"]
  },
  {
    label: "Vessel Details",
    roles: ["CENG", "CAPT", "2E", "3E", "CMATE", "2M", "3M"],
    path: RouteConstant.VESSELDETAILS
  },
  {
    label: "Inspection History",
    path: RouteConstant.INSPECTIONHISTORY
  },
  {
    label: "Chapter Progress",
  }
]

const ChapterProgress = () => {

  const inspection = useSelector((state)=> state.master.inspection)
  const master = useSelector((state)=> state.master)
  const loader = useSelector((state)=> state.options.loader)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(setLoader(true))
    dispatch(setHeaderText(`Vessel : ${master?.header}`))
  }, []);

  const [result, setResult] = useState([])

  const chapterList = chapterQueries.useChapterAssignedMutationInspectionList(
    (res)=>{
      dispatch(setLoader(false))
      setResult(res)
    }
  )

  useEffect(() => {
    chapterList.mutateAsync({vessel: master.id, inspection: inspection, user: master?.isUserWise ? master?.user?.id : ""})
  }, []);

  const handleQuestion =(item)=>{
    dispatch(setChapter({
      id: item.id,
      chapter_no: item?.chapter_no,
      name: item?.name
    }))
    navigate(RouteConstant.QUESTIONPROGRESS)
  }

  const findCompleted =(item)=>{
    const questions = item?.questions
    let success = 0
    let total = 0
    questions?.map(question=>{
      question?.checklists?.map(checklist=> {
        if(!checklist.not_applicable_checklist){
          total += 1
        }
      })
      success += question?.checklists?.filter(item=> (item?.checklistaction?.action === "YES" || item?.checklistaction?.action === "NO") && !item.not_applicable_checklist)?.length
    })
    return [success, total-success]
  }

  if(loader){
    return <Loader />
  }

  return (
    <Fragment>

      <BreadCrumbs data={breadcrumb} />

      <div className="chapters-list-cntnr mt-4">
        <section>
          <div className="chapters-list-wrpr">
            {
              result?.data?.map((item, index)=>(
                <div className="chapter-card bg-white" key={index} onClick={()=>{handleQuestion(item)}}>
                  <div className="chapter-number">
                    <div className="chapter-label">
                      Chapter<span>{item?.chapter_no}</span>
                    </div>
                  </div>
                  <div className="chapter-cntnt w-full">
                    {item?.name}
                    <div className="last-updated-on">Last updated on 29/07/2023</div>
                  </div>
                  <div className="chapter-graph">
                    <div className="chart1" id="chart-container7">
                      <DonutChart data={findCompleted(item)} />
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </section>
      </div>

    </Fragment>
  )
}

export default ChapterProgress
