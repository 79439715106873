import commentServices from "../services/commentService";
import { useMutation } from "react-query";
import dropdownService from "../services/dropdownService";

const useUserAddOrEditMutation = (onData) => {
  return useMutation(dropdownService.userAddorEdit, {
    onSuccess: (data) => onData(data),
  });
};

const useAssignedUserQuestion = (onData) => {
  return useMutation(dropdownService.assignedUserQuestion, {
    onSuccess: (data) => onData(data),
  });
};

const dropdownQueries = {
    useUserAddOrEditMutation,
    useAssignedUserQuestion
};

export default dropdownQueries;
