import React, { Fragment, useEffect, useState } from 'react'
import questionQueries from '../../queries/questionQueries'
import LoaderSmall from '../../components/LoaderSmall'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, Typography, createTheme } from '@mui/material'
import PaginationComponent from '../../components/PaginationComponent'
import { useSelector } from 'react-redux'
import dropdownQueries from '../../queries/dropdownQueries'
import { Button, Checkbox } from '@nextui-org/react'
import Empty from '../../components/Empty'
import toast from 'react-hot-toast'
import { useApi } from '../../components/ApiProvider'
import BreadCrumbs from '../../components/BreadCrumbs'
import { RouteConstant } from '../../routes/RouteConstant'
import { decode } from 'html-entities'

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    fontSize: 13
  },
});

const breadcrumb = [
  {
    label: "Vessel Details",
    roles: ["CENG", "CAPT", "2E", "3E", "CMATE", "2M", "3M"],
    path: RouteConstant.VESSELDETAILS
  },
  {
    label: "Assigned List",
  }
]

const AssignedList = () => {

  const [dropdown, setDropdown] = useState([])
  const [result, setResult] = useState([])
  const [assignedOnly, setAssignedOnly] = useState(true)
  const [search, setSearch] = useState('')
  const [chapter, setChapter] = useState('')
  
  const currentPage = useSelector((state)=> state.pagination.currentPage)
  const selectedUser = useSelector((state)=> state.master.user)

  const {apiData, setApiDataAndNotify} = useApi()

  const dropdownValue = dropdownQueries.useAssignedUserQuestion(
    (res)=>{
        setDropdown(res)
    }
  )

  useEffect(() => {
    dropdownValue.mutateAsync()
  }, []);

  const assignedList = questionQueries.useUserAssignedQuestionList(
    (res)=>{
        setResult(res)
    }
  )

  useEffect(() => {
    setCheckID([])
    assignedList.mutateAsync({
      page: currentPage,
      size: 50,
      user_id: selectedUser?.id,
      assigned_only: assignedOnly,
      search: search,
      chapter: chapter
    })
  }, [currentPage, assignedOnly, search, chapter, apiData]);

  const [checkID, setCheckID] = useState([]);

    const handleChange1 = (e) => {
      let selected = []
      const {checked} = e.target
        if(checked){
          result?.data?.items?.map(id => id?.user_id !== selectedUser?.id && selected.push(id?.id))
        }
        console.log(selected)
        setCheckID(selected)
    };
  
    const handleChange2 = (e) => {
        const {checked, value} = e.target
        const selectID = parseInt(value)
        setCheckID([...checkID, selectID])
        if(!checked){
            setCheckID(checkID.filter((item) => item !== selectID));
        }
    };

    const children = (id, assigned) => {
      return (
          <Checkbox
            disabled={assigned}
            isSelected={checkID.find(number => number === id) ? true: false}  
            onChange={handleChange2} 
            value={id} 
          />
      );
    }

    
    const alreadyCheck = () => {
      let count = 0
      result?.data?.items?.map(item => {
        if(item?.user_id !== selectedUser?.id){
          count = count + 1
        }
      })
      return count
    }
    
    const notAssignedCount = alreadyCheck()

    const userAssignQuestion = questionQueries.useUserQuestionAssign(
      (res)=>{
          if(res?.message === "success"){
              toast.success("Successfully assigned question")
              setApiDataAndNotify(res?.data)
          }
      }
    )

    const assignQuestion = () => {
      const assignUserData = {
        user_id: selectedUser?.id,
        ids: checkID
      }
      userAssignQuestion.mutateAsync(assignUserData)
    }

  return (
    <Fragment>

      <BreadCrumbs data={breadcrumb}/>

      <div className='card-shadow my-8 max-[768px]:m-2 bg-white rounded-[10px]'>
        <div className='flex justify-between max-[995px]:flex-col items-center p-3 gap-y-3'>
          {
            result?.data?.totalItems > 0 ?
            <div className='text-gray-600 text-[13px] font-medium'>{`Showing ${result?.data?.currentPage * 50 + 1} to ${result?.data?.currentPage * 50 + result?.data?.items?.length} of ${result?.data?.totalItems} entries`}</div>:
            <div></div>
          }
            <div className='flex max-[561px]:flex-col max-[561px]:w-full justify-end items-center gap-3 text-[13px] font-medium'>
                <select className='w-[300px] max-[561px]:w-full h-[40px] border rounded-[7px] px-3 mt-[1px] bg-white' onChange={(e)=>setChapter(e.target.value)}>
                    <option value="">Select Chapter</option>
                    {
                      dropdown?.chapter?.map((chapter, index)=>(
                        <option key={index} value={chapter?.id}>{chapter?.name}</option>
                      ))
                    }
                </select>
                <input onChange={(e)=>setSearch(e.target.value)} placeholder='Search...' type="text" className='w-[300px] max-[561px]:w-full h-[40px] border rounded-[7px] px-3 mt-[1px]' />
                <Checkbox rootClassName='text-[#6c757d]' isSelected={assignedOnly} onChange={(e)=>setAssignedOnly(e.target.checked)}>
                    <div className='text-[#6c757d] text-[13px] font-medium font-montserrat'>Display Only Assigned</div>
                </Checkbox>
            </div>
        </div>

        {
            assignedList?.isLoading ?
            <div className="w-full flex items-center justify-center py-40">
                <LoaderSmall />
            </div>:
            <Fragment>
              {
                result?.data?.items?.length > 0 ?
                <ThemeProvider theme={theme}>
                    <TableContainer
                    component={Paper}
                    sx={{
                        boxShadow: 'none',
                        width: '100%',
                        borderRadius: 0,
                        borderTop: '1px solid #e6e6e8',
                        borderBottom: '1px solid #e6e6e8',
                        fontWeight: 500, fontSize: "13px"
                    }}
                    >
                        <Table
                        aria-label="simple table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell align='center' width={30}>
                                      <Checkbox 
                                        isSelected={notAssignedCount === checkID.length && checkID.length > 0}
                                        isIndeterminate={notAssignedCount !== checkID.length && checkID.length > 0}
                                        onChange={handleChange1} 
                                      />
                                    </TableCell>
                                    <TableCell><b>No.</b></TableCell>
                                    <TableCell width={150}><b>Assigned to</b></TableCell>
                                    <TableCell><b>Short Question</b></TableCell>
                                    <TableCell><b>Question</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                  result?.data?.items?.map((row, index) => {
                                  const item = row?.question
                                  const assigned = row?.user_id === selectedUser?.id
                                  return(
                                    <TableRow
                                    key={index}
                                    sx={{
                                        '&:last-child td, &:last-child th': { border: 0 },
                                        fontFamily: 'Montserrat, sans-serif',
                                        bgcolor: assigned ? '#f5f5f5' : 'white'
                                    }}
                                    >
                                        <TableCell align='center'>
                                            {children(row?.id, assigned)}
                                        </TableCell>
                                        <TableCell align='center'>
                                            <Typography sx={{fontWeight: 500, fontSize: "13px"}}>
                                                {item?.chapter?.chapter_no}.{item?.section}.{item?.sub_section}
                                            </Typography>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <Typography sx={{fontWeight: 500, fontSize: "13px"}}>
                                                {row?.assignedTo?.role?.name ? <div className='text-[#059212]'>{row?.assignedTo?.role?.name}</div> : <div className='text-[#FF7F3E]'>Not Assigned</div>}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{fontWeight: 500, fontSize: "13px"}}>
                                                {item?.short_question}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{fontWeight: 500, fontSize: "13px"}}>
                                                {decode(item?.question)}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                  )}
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </ThemeProvider>:
                <Empty />
              }
                <PaginationComponent result={result} />
            </Fragment>
        }

      
      </div>
        {
          checkID.length > 0 &&
          <div className='fixed bottom-5 right-6 p-3'>
            <Button onClick={assignQuestion} variant="contained" className="bg-primary text-white font-semibold h-10 w-[100px]">
              Assign
            </Button>
          </div>
        }
    </Fragment>
  )
}

export default AssignedList