import React, { Fragment, useState } from 'react'
import Header from './Header'
import Sidebar from './Sidebar'
import { Outlet } from 'react-router-dom'
import { ChevronLeft, ChevronRight, Menu } from 'lucide-react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleBar } from '../../redux/reducers/optionsReducers'
import Loader from '../../components/Loader'

const PageLayout = () => {

  const options = useSelector((state)=> state.options)
  const dispatch = useDispatch()

  const [toggle, setToggle] = useState(options.toggleBar)

  const handleToggle = () => {
    dispatch(toggleBar())
    setToggle(!toggle);
  };

  return (
    <Fragment>

        <button className={`${toggle?"ml-[255px] z-[999] max-[768px]:ml-[255px]":"ml-[65px] max-[768px]:ml-6"} max-[768px]:flex border-1 rounded-full fixed z-50 top-[20px] bg-white`} onClick={handleToggle}>
          {
            toggle?
            <ChevronLeft size="25px" className='p-[5px]' color='gray' />:
            <Menu size="25px" className='p-[5px]' color='gray' />
          }
        </button>

      <Sidebar handleToggle={handleToggle} toggle={toggle} />
      <div className={`${toggle?"ml-[270px]":"ml-[80px]"} max-[768px]:ml-0`}>
        <Header handleToggle={handleToggle} toggle={toggle} />
      </div>
      <div className={`${toggle?"ml-[270px]":"ml-[80px]"} p-7 max-[410px]:p-0 max-[768px]:ml-0`}>
        <div className='w-full max-[410px]:p-3  text-[14px]'>
            <Outlet />
        </div>
      </div>
    </Fragment>
  )
}

export default PageLayout