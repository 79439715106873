import Input from "../components/Input"
import TextArea from "../components/TextArea"

export const ChapterFormData = [
    {
        name: "chapter_no",
        label: "Chapter",
        type: "number",
        required: true,
        max:255,
    },
    {
        name: "name",
        label: "Chapter Name",
        type: "text",
        required: true,
        max:255,
    },
    {
        name: "description",
        label: "Description",
        type: "textarea",
        required: true,
        max:255,
    },
]
