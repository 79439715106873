import React from 'react'
import AssignModal from './AssignModal'

const VesselDetailScreenChaptersAssign = ({chapter}) => {
  return (
    <div className='mt-6'>
        <div className='grid grid-cols-8 gap-3 max-[1409px]:grid-cols-6 max-[1161px]:grid-cols-4 max-[685px]:grid-cols-2'>
            {
                chapter?.map((item, index) => (
                    <div key={index} className='col-span-2 relative flex justify-between items-center bg-white shadow-md border py-[5px] px-4 rounded-[5px]'>
                        <div className="p-2 font-[600] text-[12px] flex items-center gap-2">
                            {item?.chapter_no}. {item?.name}
                            <div className='bg-green-500 min-w-6 min-h-6 w-6 h-6 rounded-full flex justify-center items-center text-white'>
                                {questionCount(item?.questions)}
                            </div>
                        </div>
                        <div>
                            <AssignModal data={item} assigned={[]} assignChapter={[]} />
                        </div>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default VesselDetailScreenChaptersAssign

const questionCount =(data)=>{
    if(data?.length > 0){
        let total = 0
        data?.map(element => {
            total += element?.vessel_questions?.length
        })
        return total
    }
    return 0
}
