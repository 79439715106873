import apiRequest from '../apis/api-request';
import commonParams from '../components/commonParams';
import { selectAuthToken } from '../redux/reducers/authReducers';
import { store } from '../redux/store';

const authService = {

    login: async (data) => {
        return apiRequest({
            method: "POST",
            url: `/api/user/login`,
            data: data
        });
    },

    userList: async (data) => {
        const token = selectAuthToken(store.getState());
        const paramsData = commonParams(data)
        return apiRequest({
            method: "GET",
            url: `/api/user${paramsData}`,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },

    createUser: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "POST",
            url: `/api/user`,
            data: data,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },

    updateUser: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "PUT",
            url: `/api/user/${data.id}`,
            data: data,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    }

};

export default authService;