import { ChevronRight } from 'lucide-react'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const BreadCrumbs = ({data}) => {

    const user = useSelector((state)=> state.auth.user)
    const role = user?.role?.role

    const arrangeData = ()=>{
        let temp = []
        data?.map((item, index)=>{
            if(item?.roles === undefined){
                temp.push(item)
            }
            const find = item?.roles?.find(i => i === role)
            if(find){
                temp.push(item)
            }
        })
        return temp
    }

    const newData = arrangeData()

  return (
    <div>
        <ol className="breadcrumb flex flex-wrap items-center gap-2">
            {
                newData?.map((item, index)=>(
                    <li key={index} className="breadcrumb-item flex items-center gap-1">
                        {
                            index > 0 &&
                            <ChevronRight size="14" />
                        }
                        {
                            data?.length === index + 1 ?
                            <span className="text-[#6c757d]">{item?.label}</span>:
                            <Link to={item?.path} className="text-[#6c757d]">{item?.label}</Link>
                        }
                    </li>
                ))
            }
        </ol>
    </div>

  )
}

export default BreadCrumbs
