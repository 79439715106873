import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useEffect, useRef, useState } from 'react';
import { IconCalendarMonth, IconCloudDownload, IconMessage, IconUser } from '@tabler/icons-react';
import { setLoader } from '../../redux/reducers/optionsReducers';
import { Spinner } from '@nextui-org/react';
import Base64Images from './Base64Images';
import PdfWrapperImage from '../../assets/images/js/PdfWrapperImage';
import moment from 'moment';

const PdfGenerator = () => {

    const pdfExportComponent = useRef(null);
    
    const inspectionData = useSelector((state)=> state.inspection)
    const vessel = useSelector((state)=> state.master.vessel)

    const loader = useSelector((state)=> state.options.loader)
    
    const dispatch = useDispatch()

    const [checklistData, setChecklistData] = useState([])

    const exportPDF = async() => {
        try {
            dispatch(setLoader(true));
    
            setTimeout(async() => {
                if (pdfExportComponent.current) {
                    await pdfExportComponent.current.save();
                }
                dispatch(setLoader(false));
            }, 100);
        } catch (error) {
            console.error('Error exporting PDF:', error);
            dispatch(setLoader(false));
        }
    }

    const checkListTypeItems = [
        {type: "PROCESS", label: "PR", color: "#FF6868"},
        {type: "HUMAN", label: "HU", color: "#03C988"},
        {type: "HARDWARE", label: "HW", color: "#3081D0"},
    ]


    useEffect(() => {
        // let tempData = []
        // inspectionData?.data?.map((item,)=>{
        //     item?.questions?.map((question)=>{
        //         question?.checklists?.map((checklist)=>{
        //             const newData = {
        //                 checklist: checklist?.checklist,
        //                 chapter: item?.name,
        //                 question: question?.question,
        //                 action: checklist?.checklistaction?.action,
        //                 not_applicable_checklist: checklist?.not_applicable_checklist
        //             }
        //             tempData.push(newData)
        //         })
        //     })
        // })
        // setChecklistData(tempData)        
    }, []);

    return (
        <Fragment>
            <div className='w-full flex flex-col items-center pdf-file gap-3'>
                <div className='w-[725px] rounded-xl'>
                    <div className='flex justify-end gap-3 w-full'>
                        <button 
                        className="text-[12px] flex items-center gap-2 shadow-md px-4 py-[7px] rounded-md text-white bg-primary" 
                        onClick={exportPDF}>
                            <IconCloudDownload size="16" color='white' />
                            Download
                        </button>
                    </div>
                </div>
                <div className='w-[725px] border-2 border-questionBorder rounded-xl p-5 bg-questionCard'>
                    <PDFExport 
                    ref={pdfExportComponent}
                    paperSize="A4"
                    margin="0.75cm"
                    fileName={`Non-conformance-report-${Date.now()}.pdf`}
                    >
                    <div className='w-full h-full'>
                        <div className='w-full h-full'>
                            <PdfWrapperImage vessel={vessel} inspectionData={inspectionData} />
                        </div>
                        <div className='font-medium mt-4'>
                            {
                                inspectionData?.data?.map((item, index)=>(
                                    <div key={index}>
                                        <div className='font-bold'>Chapter - {item?.chapter_no} {item?.name}</div>
                                        <div className='my-2'>
                                            {
                                                item?.questions?.map((question)=>(
                                                    <div className='bg-slate-200 p-2 my-2 rounded-lg border-2 border-questionBorder'>
                                                        <div>{item?.chapter_no}.{question?.section}.{question?.sub_section} &nbsp;&nbsp; {question?.question}</div>
                                                        <div className='ml-4'>
                                                            {question?.checklists?.map((checklist, checkindex)=>(
                                                                <div>
                                                                    <div className='flex my-2 gap-2 items-center'>
                                                                        <div
                                                                        style={{
                                                                            color: checklist?.checklistaction?.action === "YES" ? "#6eb347" : checklist?.checklistaction?.action === "NO" ? "#e32f2c" : "#FF8A08"
                                                                        }}
                                                                        >
                                                                           {checkindex + 1}. {checklist?.checklist} {checklist?.not_applicable_checklist && '(N/A)'}
                                                                        </div>
                                                                    </div>
                                                                    <div className='ml-4'>
                                                                        {checklist?.comments?.map((comment)=>(
                                                                            <div>
                                                                                <div className='flex my-1 gap-2 items-center'>
                                                                                    <div>
                                                                                        <div className='text-justify'>
                                                                                            {comment?.comment}
                                                                                        </div>
                                                                                        <div className='flex flex-wrap gap-2 mt-1'>
                                                                                            {
                                                                                                comment?.comment_files?.map((file)=>(
                                                                                                    <div>
                                                                                                        {
                                                                                                            file?.filename && <Base64Images imageUrl={file?.filename} />
                                                                                                        }
                                                                                                    </div>
                                                                                                ))
                                                                                            }
                                                                                        </div>
                                                                                        <div className='flex items-center gap-3 mt-1'>
                                                                                            <div className='flex items-center gap-1'>
                                                                                                <IconMessage color='green' size="14" />
                                                                                                {comment?.createdBy?.fname} {comment?.createdBy?.lname}
                                                                                            </div>
                                                                                            <div className='flex items-center gap-1'>
                                                                                                <IconCalendarMonth color='orange' size="14" />
                                                                                                {moment(comment?.createdAt).format("DD-MM-YYYY")}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        {
                                            index !== inspectionData?.data?.length - 1 &&
                                            <div className='border-b-1 my-4 border-questionBorder' />
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    </PDFExport>
                </div>
                <div className='flex gap-3'>
                    <button 
                    className="text-[12px] flex items-center gap-2 shadow-md px-4 py-[7px] rounded-md text-white bg-primary" 
                    onClick={exportPDF}>
                        <IconCloudDownload size="16" color='white' />
                        Download
                    </button>
                </div>
            </div>
            {loader && (
                <div className="overlay">
                    <Spinner />
                </div>
            )}
        </Fragment>
    );
};

export default PdfGenerator;