import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setHeaderText } from '../../redux/reducers/optionsReducers'
import vesselQueries from '../../queries/vesselQueries'
import inspectionQueries from '../../queries/inspectionQueries'
import ReactApexChart from 'react-apexcharts'
import { RouteConstant } from '../../routes/RouteConstant'
import { useNavigate } from 'react-router-dom'
import { setIsUserWise, setUser } from '../../redux/reducers/masterReducers'

const InpectionOverview = () => {

  const navigate = useNavigate()

  const dispatch = useDispatch()
  const inspection = useSelector((state)=> state.master.inspection)
  const [result, setResult] = useState([])

  useEffect(()=>{
    dispatch(setHeaderText(`Overview`))
  },[])

  const chartData = {
    series: [
      {
        name: 'Completed Checklist',
        data: result?.actionedChecklist ? result?.actionedChecklist : []
      },
      {
        name: 'Not Applicable',
        data: result?.notApplicable ? result?.notApplicable : []
      },
      {
        name: 'Pending Checklist',
        data: result?.pendingChecklist ? result?.pendingChecklist : []
      },
    ],
    options: {
      chart: {
        height: 420,
        stacked: true,
        toolbar: {
          show: true,
          tools: {
            download: false,
          },
        },
        events: {
          xAxisLabelClick: function (event, chartContext, opts, index) {
            const xAxisLabelValue = opts
            handleClick(xAxisLabelValue?.globals?.labels[xAxisLabelValue?.labelIndex])
          },
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
          width: 10,
          dataLabels: {
            total: {
              formatter: function (val, opt) {
                return `Total - ${val}`
              },
              enabled: true,
              offsetX: 0,
              style: {
                fontSize: '14px',
                fontWeight: 600,
                fontFamily: 'Montserrat, sans-serif',
              },
            },
          },
        },
      },
      colors: ['#36BA98', '#FF6969', '#FF7F3E'],
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '14px',
          colors: ['#fff', '#fff', '#fff'],
          fontFamily: 'Montserrat, sans-serif',
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff'],
      },
      title: {
        text: 'Checklist Overview',
        align: 'center',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '16px',
          fontWeight: 600,
          fontFamily: 'Montserrat, sans-serif',
        },
      },
      xaxis: {
        categories: result?.usersList ? result?.usersList : [],
        labels: {
          formatter: function (val) {
            return val + '';
          },

          style: {
            fontSize: '12px',
            fontWeight: 500,
            fontFamily: 'Montserrat, sans-serif',
          },
        },
      },
      yaxis: {
        title: {
          text: undefined,
        },
        labels: {
          formatter: function (val) {
            return val + '';
          },
          style: {
            fontSize: '12px',
            fontWeight: 500,
            fontFamily: 'Montserrat, sans-serif',
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + '';
          }
        },
        style: {
          fontSize: '12px',
          fontWeight: 500,
          fontFamily: 'Montserrat, sans-serif',
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'center',
        offsetX: 0,
        fontSize: '12px',
        fontWeight: 500,
        fontFamily: 'Montserrat, sans-serif',
      },
      grid: {
        borderColor: '#f0f0f0',
        strokeDashArray: 7,
        position: 'back',
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    },
  }

  const overview = inspectionQueries.useInspectionOverview(
    (res)=>{
      setResult(res.graph)
    }
  )

  useEffect(() => {
    overview.mutateAsync(inspection)
  }, []);

  const handleClick = (data) => {
    const user = result?.urersDetails?.find(element => element.role === data)
    dispatch(setUser(user))
    dispatch(setIsUserWise(true))
    navigate(RouteConstant.CHAPTERPROGRESS)
  }


  return (
    <div>
      <div className='grid grid-cols-12 mb-2'>
        <div className='bg-white col-span-12 max-[1088px]:col-span-12 p-3 card-shadow rounded-xl'>
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            height={720}
            type="bar"
          />
        </div>
      </div>
      <p className='text-[12px] font-medium italic col-span-12 text-[red]'>Clicking on the x-axis label will navigate you to detailed information related to this category. Explore further insights by clicking!</p>
    </div>
  )
}

export default InpectionOverview