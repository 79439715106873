export const VesselFormData = [
    {
        name: "name",
        label: "Vessel Type",
        type: "text",
        required: true,
        max:255,
    },
    {
        name: "imo_no",
        label: "IMO Number",
        type: "number",
        required: true,
    },
    {
        name: "built",
        label: "Built",
        type: "number",
        required: true,
        grid: 6
    },
    {
        name: "type",
        label: "Type",
        type: "text",
        required: true,
    },
    {
        name: "dwt",
        label: "DWT",
        type: "number",
        required: true,
    }
]
