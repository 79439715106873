import { Fragment, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import LoginScreen from "./screens/Login/LoginScreen";
import { useSelector } from "react-redux";
import SuperAdminRoutes from "./routes/SuperAdminRoutes";
import { useNavigate } from "react-router-dom";
import { RouteConstant } from "./routes/RouteConstant";
import SocketProvider from "./socket/SocketProvider";
import CaptainRoutes from "./routes/CaptainRoutes";
import CrewRoutes from "./routes/CrewRoutes";

function App() {
  
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);

  return (
    <Fragment>
      {
          isAuthenticated ?

          (
            user?.role?.role === "SADMIN" ?
            <SuperAdminRoutes />:
            user?.role?.role === "CAPT" ?
            <CaptainRoutes />:
            <CrewRoutes />
          ):
          <LoginScreen />
      }
      <Toaster
      position="top-center"
      reverseOrder={false}
      />
      <SocketProvider />
    </Fragment>
  );
}

export default App;
