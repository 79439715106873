import React, { Fragment } from 'react';
import { Controller } from 'react-hook-form';
import { ValidationErrorMaster } from './validationError'; // Assuming you have a ValidationError component

const SelectMaster = ({ control, item, errors }) => {
  return (
    <Fragment>
      <div className="text-[12px] h-4 font-[500]">
        {item?.label} {item?.required && <span className="text-[red]">*</span>}
      </div>
      <Controller
        name={item?.name}
        control={control}
        defaultValue=""
        render={({ field }) => (
          <select
            {...field}
            className="w-full h-[40px] border rounded-[7px] px-3 mt-[1px] text-[13px] bg-white"   
          >
            <option value="">Select {item?.label}</option>
            {item?.options?.map((option, index) => (
              <option key={index} value={option.id}>
                {option.name}
              </option>
            ))}
          </select>
        )}
      />
      <ValidationErrorMaster error={errors?.[item?.name]} />
    </Fragment>
  );
};

export default SelectMaster;
