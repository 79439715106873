import React, { useEffect, useState } from 'react'
import moment from 'moment';

const PdfWrapperImage = ({vessel, inspectionData}) => {

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  useEffect(() => {

    let tempData = []
    inspectionData?.data?.map((item,)=>{
        item?.questions?.map((question)=>{
            question?.checklists?.map((checklist)=>{
                checklist?.comments?.map((comment)=>{
                    const newData = {
                        data: comment?.createdAt
                    }
                    tempData.push(newData)
                })
            })
        })
    })
    
    if(tempData?.length > 0){
      const soretedFirstandLastDate = tempData?.sort((a, b) => {
          return new Date(a.data) - new Date(b.data);
      });

      
      const firstDate =  soretedFirstandLastDate[0]?.data
      const lastDate = soretedFirstandLastDate[soretedFirstandLastDate?.length - 1]?.data

      setStartDate(moment(firstDate).format('DD/MM/YYYY'))
      setEndDate(moment(lastDate).format('DD/MM/YYYY'))
    }
    
  }, []);

  return (
    <div className='w-full h-full'>
        <div className="h-[200px] rounded-2xl text-white grid bg-cover bg-[url('https://t3.ftcdn.net/jpg/03/30/41/50/360_F_330415060_YoXkJiDqYAGjzhHD4Iu2CTl9AYlHunAI.jpg')]">
            <div className="col-start-1 row-start-1 rounded-2xl bg-gray-800 bg-opacity-70 w-full h-full"></div>
            <div className="col-start-1 flex justify-center flex-col items-center w-full h-full row-start-1 rounded-2xl mx-auto my-auto">
                <h1 className="font-bold text-[18px]">SIRE 2.0 Non conformance report</h1>
                <h1 className="font-bold text-[18px]">{vessel?.name}</h1>
                <div className='w-full flex flex-col items-center justify-center gap-1 text-[12px] mt-2'>
                    <div>DWT: {vessel?.dwt}</div>
                    <div>Built: {vessel?.built}</div>
                    <div>Type: {vessel?.type}</div>
                    {/* <div>Owner: {vessel?.owner}</div> */}
                    <div>Date: {startDate} {(startDate && endDate) && "to"} {endDate}</div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PdfWrapperImage