import React, { Fragment, useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure, Textarea, Checkbox, Spinner } from "@nextui-org/react";
import { IconDeviceFloppy, IconMessage, IconX } from "@tabler/icons-react";
import { CalendarRange, UploadCloud, UserRound, X } from "lucide-react";
import moment from "moment";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Image, Upload } from "antd";
import fileuploadQueries from "../../queries/fileuploadQueries";
import imageCompression from "browser-image-compression";

const CommentModal =({data, action, checklist, createComment})=>{

    const [comment, setComment] = useState("")
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [fileList, setFileList] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const inspection = useSelector((state)=> state.master.inspection)

    const fileupload = fileuploadQueries.useFileUploadMutationMultiple(
        (res)=>{

            if(res?.message === "Success"){
                let arr = []
                res?.files?.forEach((item)=>{
                    arr.push(item?.filename)
                })

                const dataValues = {
                    checklist_id: checklist,
                    comment: comment,
                    inspection_id: inspection,
                    files: arr
                }

                createComment.mutateAsync(dataValues)
                setComment("")
                setFileList([])

            }
        }
    )

    const onSubmit=()=>{

        if(!comment && fileList.length === 0){
           return toast.error("Please enter comment...")
        }

        const newData = new FormData();
        fileList.forEach((item)=>{
            newData.append('file', item.originFileObj);
        })
        fileupload.mutateAsync(newData)

    }

    const handleChange = async ({ fileList: newFileList }) => {
        let arr = []
        newFileList.map(item=>{
            if(item.type === 'image/jpeg' || item.type === 'image/png'){
                arr.push(item)
                item.status = "done"
            }else{
                toast.error('Only image format allowed...')
                return
            }
        })
        setFileList(arr)
    };

    const uploadButton = (
        <button
          className="flex flex-col items-center justify-center gap-1"
          type="button"
        >
          <UploadCloud size="16"  />
          <div className="text-xs">Upload</div>
        </button>
    );


    const onPreview = async (file) => {
        
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        setPreviewOpen(true)
        setPreviewImage(src)

    };

    const convertLineBreaks = (text) => {
        return text?.split('\n')?.map((line, index) => (
          <Fragment key={index}>
            {line}
            <br />
          </Fragment>
        ));
    };
    

  return (
    <Fragment>
        <button onClick={onOpen} className="w-[30px] h-[30px] flex items-center justify-center rounded-full ring-2 ring-blue-500">
            <IconMessage size="16" className='text-iconColor' />
        </button>
        <Modal 
            isDismissable={false}
            backdrop="opaque"
            size="3xl"
            scrollBehavior="inside"
            isOpen={isOpen} 
            onOpenChange={onOpenChange}
            classNames={{
            backdrop: "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20"
            }}
        >
            <ModalContent>
            {(onClose) => (
                <Fragment>
                    <ModalHeader className="flex flex-col gap-1 text-left text-[16px]">Add Comment</ModalHeader>
                    <ModalBody>
                        <Textarea
                        value={comment}
                        onChange={(e)=>{setComment(e.target.value)}}
                        placeholder="Enter your comment..."
                        className="text-area-comment"
                        minRows={4}
                        classNames={{
                            inputWrapper:"bg-white border-1 border-gray-300",
                            input: "font-medium text-[12px]",
                        }}/>
                        {
                            action?.action === "NO" && 
                            <Checkbox color="success" classNames={{icon:"text-white"}}>
                                <div className="text-[12px] font-semibold">
                                    Add it to defect tracker
                                </div>
                            </Checkbox>
                        }
                        <Upload
                        action="/api/upload"
                        listType="picture-card"
                        fileList={fileList}
                        onPreview={onPreview}
                        onChange={handleChange}
                        >
                        {fileList.length >= 8 ? null : uploadButton}
                        </Upload>
                        <Image
                        preview={{
                        onVisibleChange: () => setPreviewOpen(false),
                        visible: previewOpen,
                        src: previewImage,
                        closeIcon:  <div onClick={()=>setPreviewOpen(false)}>
                                        <X size="16" />
                                    </div>
                        }}/>
                        
                        <div className="text-[12px] font-semibold">
                                <div className="bg-gray-300 py-3 px-3 rounded-t-xl">
                                    Recent comments
                                </div>
                                <div className="border border-default-200 rounded-b-lg">
                                    {
                                        data?.map((item, index)=>(
                                            <div key={index} className="flex items-start px-4 py-3 gap-3 border-b border-default-100">
                                                <div className="flex justify-center items-center rounded-full w-[30px] h-[30px] min-w-[30px] bg-iconBgColorMessage">
                                                    <IconMessage size="16" color="white" />
                                                </div>
                                                <div className="flex flex-col gap-3">
                                                    {item?.comment && <div>{convertLineBreaks(item?.comment)}</div>}
                                                    {item?.comment_files?.length > 0 && <ImageList data={item?.comment_files} />}
                                                    <div className="flex items-center gap-4">
                                                        <div className="flex items-center gap-2 "><UserRound size="14" className="text-green-600" /> {item?.createdBy?.fname} {item?.createdBy?.lname}</div>
                                                        <div className="flex items-center gap-2 "><CalendarRange size="14" className="text-orange-600" />{moment(item?.createdAt).format('DD-MM-YYYY')}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <div className="flex gap-2 text-[12px] font-semibold">
                            {
                                (!createComment?.isLoading && !fileupload?.isLoading)  ?
                                <button onClick={onSubmit} className="flex gap-2 items-center bg-primary px-4 rounded-lg py-2 text-white">
                                    <div><IconDeviceFloppy size="14" /></div>
                                    <div>Save</div>
                                </button>:
                                <button disabled className="flex gap-2 items-center bg-gray-300 text-gray-500 cursor-not-allowed px-4 rounded-lg py-2">
                                    <div className="h-[14px]">
                                        <Spinner classNames={{
                                        circle1:"w-[14px] h-[14px]", 
                                        circle2:"w-[14px] h-[14px]",
                                        wrapper:"w-[14px] h-[14px]"
                                        }}
                                        size="sm" />
                                    </div>
                                    <div>Loading</div>
                                </button>
                            }
                            <button onClick={onClose} className="flex gap-2 items-center bg-red-600 px-4 rounded-lg py-2 text-white">
                                <div><IconX size="14" /></div>
                                <div>Cancel</div>
                            </button>
                        </div>
                    </ModalFooter>
                </Fragment>
            )}
            </ModalContent>
        </Modal>
    </Fragment>
  );
}

export default CommentModal

const ImageList =({data})=>{

    return(
        <div className="flex gap-2 flex-wrap">
            {
                data?.map((item, index)=>(
                    <div key={index}>
                        <Image
                        className="rounded-md"
                        width={50}
                        height={50}
                        src={process.env.REACT_APP_BASE_URL+`/api/fileupload/${item?.filename}`}
                        preview={{
                            src: process.env.REACT_APP_BASE_URL+`/api/fileupload/${item?.filename}`,
                        }}
                        />
                    </div>
                ))
            }
        </div>
    )
}


