import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  toggleBar: true,
  headerText: "Dashboard",
  activeTab: "",
  loader: false
};

const optionSlice = createSlice({
  name: 'options',
  initialState,
  reducers: {
    toggleBar: (state) => {
      state.toggleBar = !state.toggleBar;
    },
    setHeaderText: (state, action) => {
      state.headerText = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setHeaderTextIntitial:(state, action) => {
      state.headerText =initialState.headerText;
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
  },
});

export const { toggleBar, setHeaderText, setActiveTab, setHeaderTextIntitial, setLoader } = optionSlice.actions;
export default optionSlice.reducer;