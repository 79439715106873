import apiRequest from '../apis/api-request';
import commonParams from '../components/commonParams';
import { selectAuthToken } from '../redux/reducers/authReducers';
import { store } from '../redux/store';

const vesselService = {
    vesselList: async (data) => {
        const paramsData = commonParams(data)
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "GET",
            url: `/api/vessel${paramsData}`,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    }, 
    
    vesselCreate: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "POST",
            url: `/api/vessel`,
            data: data,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    }, 
    
    vesselUpdate: async (data) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "PUT",
            url: `/api/vessel/${data.id}`,
            data: data,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    }, 
    
    vesselDelete: async (id) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "DELETE",
            url: `/api/vessel/${id}`,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },
        
    vesselView: async (id) => {
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "GET",
            url: `/api/vessel/${id}`,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    },

    ocimfvesselList: async (data) => {
        const paramsData = commonParams(data)
        const token = selectAuthToken(store.getState());
        return apiRequest({
            method: "POST",
            url: `/api/ocimf/vesselsearch`,
            headers: {
                Authorization: "Bearer " + token
            },
        });
    }, 

};

export default vesselService;