import React, { Fragment, useEffect, useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure} from "@nextui-org/react";
import { Transfer } from "antd";
import { useSelector } from "react-redux";
import { IconPlus } from "@tabler/icons-react";
import questionQueries from "../../queries/questionQueries";
import { useApi } from "../../components/ApiProvider";

const AssignModal =({data, assigned})=>{
  const {isOpen, onOpen, onOpenChange} = useDisclosure();
  const master = useSelector((state)=>state.master)
  const {setApiDataAndNotify} = useApi()
  
    const questionsList = questionQueries.useQuestionChapterWiseList(
      (res)=>{
        getMock(res?.data)
      }
    )
    const handleOpen = ()=>{
      questionsList.mutateAsync({vessel:master.id, chapter:data.id})
      onOpen()
    }


    const [targetKeys, setTargetKeys] = useState([])
    const [mockData, setMockData] = useState([]);
      
    const getMock = (qst) => {
        let tempMockData = [];
        let tempTargetKey = []
        qst?.map((element, index)=>{
            const newData = {
                key: element.id,
                title: <div className="font-poppins">{`${data?.chapter_no}.${element?.section !== null ? element?.section:0}.${element?.sub_section !== null ? element?.sub_section:0}  ${element.short_question} (${element?.tag})`}</div>,
            }
            tempMockData.push(newData)
            if(element?.vessel_questions?.length > 0){
              tempTargetKey.push(element.id)
            }
        })
  
        if(assigned?.length > 0){
            assigned.map(element=>{
                tempTargetKey.push(element.id)
            })
        }
        setTargetKeys(tempTargetKey)
        setMockData(tempMockData);
    }

    const assignQuestion = questionQueries.useQuestionAssign(
      (res)=>{
        setApiDataAndNotify(res)
        questionsList.mutateAsync({vessel:master.id, chapter:data.id})
      }
    )
  
    const handleChange=(newTargetKeys)=>{
        const dataValues = {
          vessel_id: master.id,
          questions: newTargetKeys,
          chapter_id: data.id
        }
        assignQuestion.mutateAsync(dataValues)
        setTargetKeys(newTargetKeys)
    }
  

  return (
    <Fragment>
      <button onClick={handleOpen}>
          <IconPlus size="16" />
      </button>
      <Modal 
        backdrop="opaque"
        size="full"
        isOpen={isOpen} 
        onOpenChange={onOpenChange}
        classNames={{
          backdrop: "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20"
        }}
      >
        <ModalContent>
          {(onClose) => (
            <Fragment>
              <ModalHeader className="flex flex-col gap-1 text-center">Assign Question</ModalHeader>
              <ModalBody>
                <Transfer
                dataSource={mockData}
                listStyle={{
                width: "50%",
                height: 600,
                }}
                targetKeys={targetKeys}
                onChange={handleChange}
                render={item=>item.title}
                />
              </ModalBody>
              <ModalFooter>

              </ModalFooter>
            </Fragment>
          )}
        </ModalContent>
      </Modal>
    </Fragment>
  );
}

export default AssignModal