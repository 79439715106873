import { Button, Pagination } from '@nextui-org/react'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setQuestionPage } from '../../../redux/reducers/questionReducers'

const PaginationComponent = ({result}) => {

    const dispatch = useDispatch()
    const currentPage = useSelector((state)=> state.question.currentPage)

    const handlePagination = async (page) => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        dispatch(setQuestionPage(currentPage + page))
    }

    const handlePage = (page) => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        dispatch(setQuestionPage(page-1))
    }

  return (
    <Fragment>
        {
            result?.data?.totalPages > 1 &&
            <div className="flex gap-3 justify-center">
            <Button
            size="sm"
            isDisabled={currentPage === 0}
            variant="flat"
            color="primary"
            onPress={()=>handlePagination(-1)}
            >
            <ChevronLeft size={16} /> Previous
            </Button>
            <Pagination
            total={result?.data?.totalPages}
            color="primary"
            page={currentPage+1}
            onChange={(page)=>handlePage(page)}
            />
            <Button
            size="sm"
            isDisabled={currentPage === result?.data?.totalPages-1}
            variant="flat"
            color="primary"
            onPress={()=>handlePagination(+1)}
            >
            Next <ChevronRight size={16} />
            </Button>
        </div>
        }
    </Fragment>
  )
}

export default PaginationComponent
