import React, { Fragment, useRef, useState } from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure, Textarea, Checkbox, Spinner, Button, Input } from "@nextui-org/react";
import { IconCaretLeft, IconCaretRight, IconChevronDownLeft, IconChevronDownRight, IconChevronLeft, IconDeviceFloppy, IconFile, IconMessage, IconPdf, IconPlus, IconScript, IconX } from "@tabler/icons-react";
import { CalendarRange, UploadCloud, UserRound, X } from "lucide-react";
import moment from "moment";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { Image, Upload } from "antd";
import fileuploadQueries from "../../queries/fileuploadQueries";
import commentQueries from "../../queries/commentQueries";
import LoaderSmall from "../../components/LoaderSmall";
import { IconChevronRight } from "@tabler/icons-react";
import { fileImages, fileTypes } from "../../utils/filetypes";
import documentQueries from "../../queries/documentQueries";
import { useApi } from "../../components/ApiProvider";

const QuestionDocument =()=>{

    const [title, setTitle] = useState("")
    const [location, setLocation] = useState("")
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [fileList, setFileList] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');

    const vessel = useSelector((state)=> state.master.vessel)
    const question = useSelector((state)=> state.master.question)

    const { setApiDataAndNotify } = useApi();

    const handleOpen =()=>{
        onOpen()
    }

    const questionCommentCreate = documentQueries.useDocumentMutationCreate(
        (res)=>{
            setApiDataAndNotify(res)
        }
    )

    const onSubmit=()=>{

        if(!title){
           return toast.error("Please enter comment...")
        }

        const newData = new FormData();

        fileList.forEach((item)=>{
            newData.append('file', item?.file);
        })

        newData.append('question_id', question.id)
        newData.append('title', title)
        newData.append('location', location)
        newData.append('vessel_id', vessel.id)

        questionCommentCreate.mutateAsync(newData)
        setTitle("")
        setLocation("")
        onOpenChange(false)
        setFileList([])

    }

    const handleChange = async (event) => {
        const selectedFiles = Array.from(event.target.files);
        const newFilesWithPreviews = [];

        await Promise.all(selectedFiles.map(file => {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = () => {
                    newFilesWithPreviews.push({
                        src: reader.result,
                        name: file.name,
                        file: file,
                        preview: URL.createObjectURL(file),
                    });
                    resolve();
                };
                reader.readAsDataURL(file);
            });
        }));

        event.target.value = '';

        setFileList(prevFileList => [
            ...prevFileList,
            ...newFilesWithPreviews
        ]);
    };
    

    const uploadButton = (
        <button
          className="flex flex-col items-center justify-center gap-1"
          type="button"
        >
          <UploadCloud size="22"  />
          <div className="text-[13px] font-semibold">Upload</div>
        </button>
    );

    const onPreview = async (file) => {
        
        let src = file;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        setPreviewOpen(true)
        setPreviewImage(src)

    };

    const scrollContainerRef = useRef(null);

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({ left: -250, behavior: 'smooth' });
        }
    }

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({ left: 250, behavior: 'smooth' });
        }
    }
    
    const removeFile = (index)=>{
        fileList.splice(index, 1);
        setFileList([...fileList]);
    }


  return (
    <Fragment>
        <Button onClick={handleOpen} className="flex items-center justify-center bg-primary text-white font-semibold text-[12px]">
            <IconPlus size="16" />
            New Document
        </Button>
        <Modal
            isDismissable={false}
            backdrop="opaque"
            size="4xl"
            scrollBehavior="inside"
            isOpen={isOpen} 
            onOpenChange={onOpenChange}
            classNames={{
            backdrop: "bg-gradient-to-t from-zinc-900 to-zinc-900/10 backdrop-opacity-20",
            base: "min-h-[600px] overflow-auto",
            }}
        >
            <ModalContent>
            {(onClose) => (
                <Fragment>
                    <ModalHeader className="flex flex-col gap-1 text-left text-[16px]">Create Document</ModalHeader>
                    <ModalBody>

                        <Input
                        value={title}
                        onChange={(e)=>{setTitle(e.target.value)}}
                        label="Title"
                        labelPlacement="outside"
                        placeholder="Enter title"
                        className="input-title"
                        classNames={{
                            label: "text-[12px] font-semibold",
                            inputWrapper:"bg-white border-1 border-gray-200 rounded-[8px] h-[44px]",
                            input: "font-medium text-[12px]"
                        }}
                        />

                        <Input
                        value={location}
                        label="Location"
                        labelPlacement="outside"
                        onChange={(e)=>{setLocation(e.target.value)}}
                        placeholder="Enter location"
                        className="input-title"
                        classNames={{
                            label: "text-[12px] font-semibold",
                            inputWrapper:"bg-white border-1 border-gray-200 rounded-[8px] h-[44px]",
                            input: "font-medium text-[12px]"
                        }}
                        />

                        <Fragment>
                            <div className="relative">
                                <div ref={scrollContainerRef} className="flex gap-4 overflow-x-auto scrollbar-hide relative rounded-xl">
                                    <div className="w-[250px] h-[150px] min-w-[250px] min-h-[150px] rounded-xl bg-gray-50 border border-gray-200 relative flex justify-center items-center">
                                        <input className="absolute top-0 left-0 w-full h-full opacity-0" type="file" multiple onChange={handleChange} />
                                        {uploadButton}
                                    </div>
                                    {
                                        fileList?.map((item, index)=>(
                                            <ImagePreview key={index} index={index} item={item} onPreview={onPreview} removeFile={removeFile} />
                                        ))
                                    }
                                </div>
                                {
                                    fileList.length > 3 &&
                                    <Fragment>
                                        <button onClick={scrollRight} className="absolute left-[-10px] z-10 top-[60px] bg-white h-[30px] w-[30px] flex justify-center items-center rounded-full shadow-[0_0_10px_0_rgba(0,0,0,0.5)]">
                                            <IconChevronLeft size="20" className="text-black" />
                                        </button>
                                        <button onClick={scrollLeft} className="absolute right-[-10px] z-10 top-[60px] bg-white h-[30px] w-[30px] flex justify-center items-center rounded-full shadow-[0_0_10px_0_rgba(0,0,0,0.5)]">
                                            <IconChevronRight size="20" className="text-black" />
                                        </button>
                                    </Fragment>
                                }
                            </div>
                        </Fragment>
                        <Image
                        preview={{
                        onVisibleChange: () => setPreviewOpen(false),
                        visible: previewOpen,
                        src: previewImage,
                        closeIcon:  <div onClick={()=>setPreviewOpen(false)}>
                                        <X size="16" />
                                    </div>
                        }}/>
                    

                    </ModalBody>
                    <ModalFooter>
                        <div className="flex justify-end gap-2 text-[12px] font-semibold">
                            {
                                (!questionCommentCreate?.isLoading)  ?
                                <button onClick={onSubmit} className="flex gap-2 items-center bg-primary px-4 rounded-lg py-2 text-white">
                                    <div><IconDeviceFloppy size="14" /></div>
                                    <div>Save</div>
                                </button>:
                                <button disabled className="flex gap-2 items-center bg-gray-300 text-gray-500 cursor-not-allowed px-4 rounded-lg py-2">
                                    <div className="h-[14px]">
                                        <Spinner classNames={{
                                        circle1:"w-[14px] h-[14px]", 
                                        circle2:"w-[14px] h-[14px]",
                                        wrapper:"w-[14px] h-[14px]"
                                        }}
                                        size="sm" />
                                    </div>
                                    <div>Loading</div>
                                </button>
                            }
                        </div>
                        <button onClick={onClose} className="flex gap-2 items-center bg-red-600 px-4 rounded-lg py-2 text-[12px] font-semibold text-white">
                            <div><IconX size="14" /></div>
                            <div>Cancel</div>
                        </button>
                    </ModalFooter>
                </Fragment>
            )}

            </ModalContent>
        </Modal>
    </Fragment>
  );
}

export default QuestionDocument


const ImagePreview =({item, key, index, onPreview, removeFile})=> {

    const [blur, setBlur] = useState(false);

    function findCategoryByFileType(mimeType) {
        for (const [category, types] of Object.entries(fileTypes)) {
            if (types.includes(mimeType)) {
                return fileImages?.[category];
            }
        }
        return <img src="https://images.vexels.com/media/users/3/276661/isolated/preview/614fa2f6000e812cb013b82d5ed0eb21-blue-folder-squared.png" className="w-[100px] h-[100px]" />;
    }

    const previewFile = async()=>{
        if(item?.file?.type === "application/pdf"){
            const response = await fetch(item.src);
            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
        }else if(item?.file?.type === "text/html"){
            const response = await fetch(item.src);
            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
        }else{
            toast.error("Preview not available")
        }
    }

    const handleRemove = async()=>{
        removeFile(index)
    }

    return(
        <Fragment>
            {
                (item?.file?.type === "image/png" || item?.file?.type === "image/jpeg" || item?.file?.type === "image/jpg") ?                                       
                <div
                onMouseEnter={()=>setBlur(true)} 
                onMouseLeave={()=>setBlur(false)}
                key={key} 
                className="relative"
                >
                    <img
                    src={item.preview} 
                    alt={item.name}
                    style={{filter: blur ? "blur(5px)" : "none"}}
                    className="w-[250px] h-[150px] min-w-[250px] min-h-[150px] rounded-xl object-cover" 
                    />
                    {
                    blur &&
                    <Fragment>
                        <button
                        onClick={()=>onPreview(item?.preview)}
                        className="absolute top-[60px] left-[30px] rounded-xl text-[12px] font-semibold text-primary bg-white px-3 py-2">
                            Preview
                        </button>
                        <button
                        onClick={handleRemove}
                        className="absolute top-[60px] right-[30px] rounded-xl text-[12px] font-semibold text-primary bg-white px-3 py-2">
                            Remove
                        </button>
                    </Fragment>
                }
                </div> :
                <div
                onMouseEnter={()=>setBlur(true)}
                onMouseLeave={()=>setBlur(false)}
                key={key} 
                className="w-[250px] h-[150px] min-w-[250px] min-h-[150px] rounded-xl bg-gray-50 border border-gray-200 relative"
                >
                <div
                style={{filter: blur ? "blur(5px)" : "none"}}
                className="w-full h-full flex flex-col gap-2 text-center justify-center items-center"
                >
                    <div className="w-[100px] h-[100px] flex items-center justify-center">
                        {findCategoryByFileType(item?.file?.type)}
                    </div>
                    <div className="text-[12px] font-semibold">{(item?.name).slice(0, 20)} {item?.name?.length > 20 ? "..." : ""}</div>
                </div>
                {
                    blur &&
                    <Fragment>
                        <button
                        onClick={previewFile}
                        className="absolute top-[60px] left-[30px] rounded-xl text-[12px] font-semibold text-primary bg-white px-3 py-2">
                            Preview
                        </button>
                        <button
                        onClick={handleRemove}
                        className="absolute top-[60px] right-[30px] rounded-xl text-[12px] font-semibold text-primary bg-white px-3 py-2">
                            Remove
                        </button>
                    </Fragment>
                }
                </div>
            }
        </Fragment>
    )

}