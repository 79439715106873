import { Radio, RadioGroup } from '@nextui-org/react'
import React, { Fragment } from 'react'
import { Controller } from 'react-hook-form'

const RadioGroups = ({data, name, control}) => {
  return (
    <Fragment>
        <Controller
        name={name}
        control={control}
        render={({ field }) => (
        <RadioGroup {...field} orientation='horizontal'>
            {data?.map((item, index) =>(
                <Radio key={index} value={item.value}>{item.label}</Radio>
            ))}
        </RadioGroup>
        )}
        />
    </Fragment>
  )
}

export default RadioGroups
