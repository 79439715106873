import React from 'react';
import ReactDOM from "react-dom/client";
import App from './App';
import './index.css'
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import reportWebVitals from "./reportWebVitals";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux/store';
import { ApiProvider } from './components/ApiProvider';

const root = ReactDOM.createRoot(document.getElementById("root"));

const queryClient = new QueryClient();

root.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ApiProvider>
            <App />
          </ApiProvider>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </BrowserRouter>
);

reportWebVitals();



